// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/monochromeColoredOnDarkGroupIcons.template
// file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Fluo = svgLazyLoader('supplier/colored/fluo')
const Noctilien = svgLazyLoader('supplier/colored/noctilien')
const Accor = svgLazyLoader('supplier/colored/accor')
const Aleop = svgLazyLoader('supplier/colored/aleop')
const AleopMonochrome = svgLazyLoader('supplier/monochrome/aleop')
const AllianzTravel = svgLazyLoader('supplier/colored/allianz_travel')
const AveRenfe = svgLazyLoader('supplier/colored/ave_renfe')
const AveRenfeMonochrome = svgLazyLoader('supplier/monochrome/ave_renfe')
const Avis = svgLazyLoader('supplier/colored/avis')
const AvisBudget = svgLazyLoader('supplier/colored/avis_budget')
const AvisBudgetMonochrome = svgLazyLoader('supplier/monochrome/avis_budget')
const AvisMonochrome = svgLazyLoader('supplier/monochrome/avis')
const Blablabus = svgLazyLoader('supplier/colored/blablabus')
const BlablabusMonochrome = svgLazyLoader('supplier/monochrome/blablabus')
const Blablacar = svgLazyLoader('supplier/colored/blablacar')
const BlablacarMonochrome = svgLazyLoader('supplier/monochrome/blablacar')
const CarReservationMonochrome = svgLazyLoader('supplier/monochrome/car_reservation')
const Cff = svgLazyLoader('supplier/colored/cff')
const CffIc = svgLazyLoader('supplier/colored/cff_ic')
const CffIcMonochrome = svgLazyLoader('supplier/monochrome/cff_ic')
const CffIr = svgLazyLoader('supplier/colored/cff_ir')
const CffIrMonochrome = svgLazyLoader('supplier/monochrome/cff_ir')
const CffMonochrome = svgLazyLoader('supplier/monochrome/cff')
const CffRe = svgLazyLoader('supplier/colored/cff_re')
const CffReMonochrome = svgLazyLoader('supplier/monochrome/cff_re')
const Cfl = svgLazyLoader('supplier/colored/cfl')
const CflMonochrome = svgLazyLoader('supplier/monochrome/cfl')
const City = svgLazyLoader('supplier/colored/city')
const DarkChauffeurInoui = svgLazyLoader('supplier/dark/chauffeur_inoui')
const DarkCoeurInoui = svgLazyLoader('supplier/dark/coeur_inoui')
const DarkConfortInoui = svgLazyLoader('supplier/dark/confort_inoui')
const DarkFlecheInoui = svgLazyLoader('supplier/dark/fleche_inoui')
const DarkJuniorEtCieInoui = svgLazyLoader('supplier/dark/junior_et_cie_inoui')
const DarkLeBarInoui = svgLazyLoader('supplier/dark/le_bar_inoui')
const DarkLocationInoui = svgLazyLoader('supplier/dark/location_inoui')
const DarkMesBagagesInoui = svgLazyLoader('supplier/dark/mes_bagages_inoui')
const DarkTgv = svgLazyLoader('supplier/dark/tgv')
const DarkWifiInoui = svgLazyLoader('supplier/dark/wifi_inoui')
const Db = svgLazyLoader('supplier/colored/db')
const DbMonochrome = svgLazyLoader('supplier/monochrome/db')
const DbSncf = svgLazyLoader('supplier/colored/db_sncf')
const DbSncfMonochrome = svgLazyLoader('supplier/monochrome/db_sncf')
const Eurocity = svgLazyLoader('supplier/colored/eurocity')
const EurocityMonochrome = svgLazyLoader('supplier/monochrome/eurocity')
const Eurostar = svgLazyLoader('supplier/colored/eurostar')
const EurostarMonochrome = svgLazyLoader('supplier/monochrome/eurostar')
const Flixbus = svgLazyLoader('supplier/colored/flixbus')
const FlixbusMonochrome = svgLazyLoader('supplier/monochrome/flixbus')
const FluoMonochrome = svgLazyLoader('supplier/monochrome/fluo')
const Frecciarossa = svgLazyLoader('supplier/colored/frecciarossa')
const FrecciarossaMonochrome = svgLazyLoader('supplier/monochrome/frecciarossa')
const GoogleMaps = svgLazyLoader('supplier/colored/google_maps')
const GoogleMapsMonochrome = svgLazyLoader('supplier/monochrome/google_maps')
const GrandeVitesseMonochrome = svgLazyLoader('supplier/monochrome/grande_vitesse')
const Ice = svgLazyLoader('supplier/colored/ice')
const IceMonochrome = svgLazyLoader('supplier/monochrome/ice')
const Intercites = svgLazyLoader('supplier/colored/intercites')
const IntercitesDeNuit = svgLazyLoader('supplier/colored/intercites_de_nuit')
const IntercitesDeNuitMonochrome = svgLazyLoader('supplier/monochrome/intercites_de_nuit')
const IntercitesMonochrome = svgLazyLoader('supplier/monochrome/intercites')
const InternationalMonochrome = svgLazyLoader('supplier/monochrome/international')
const Lime = svgLazyLoader('supplier/colored/lime')
const Lio = svgLazyLoader('supplier/colored/lio')
const LioMonochrome = svgLazyLoader('supplier/monochrome/lio')
const MaLocationAvis = svgLazyLoader('supplier/colored/ma_location_avis')
const MaLocationAvisMonochrome = svgLazyLoader('supplier/monochrome/ma_location_avis')
const Mobigo = svgLazyLoader('supplier/colored/mobigo')
const MobigoMonochrome = svgLazyLoader('supplier/monochrome/mobigo')
const NavetteMonochrome = svgLazyLoader('supplier/monochrome/navette')
const NederlandseSpoorwegen = svgLazyLoader('supplier/colored/nederlandse_spoorwegen')
const NederlandseSpoorwegenMonochrome = svgLazyLoader('supplier/monochrome/nederlandse_spoorwegen')
const Nightjet = svgLazyLoader('supplier/colored/nightjet')
const NightjetMonochrome = svgLazyLoader('supplier/monochrome/nightjet')
const Nomad = svgLazyLoader('supplier/colored/nomad')
const NomadMonochrome = svgLazyLoader('supplier/monochrome/nomad')
const Novotel = svgLazyLoader('supplier/colored/novotel')
const Obb = svgLazyLoader('supplier/colored/obb')
const ObbMonochrome = svgLazyLoader('supplier/monochrome/obb')
const Osmand = svgLazyLoader('supplier/colored/osmand')
const OsmandMonochrome = svgLazyLoader('supplier/monochrome/osmand')
const OuigoEspagneMonochrome = svgLazyLoader('supplier/monochrome/ouigo_espagne')
const OuigoGrandeVitesse = svgLazyLoader('supplier/colored/ouigo_grande_vitesse')
const OuigoGrandeVitesseMonochrome = svgLazyLoader('supplier/monochrome/ouigo_grande_vitesse')
const OuigoVitesseClassique = svgLazyLoader('supplier/colored/ouigo_vitesse_classique')
const OuigoVitesseClassiqueMonochrome = svgLazyLoader('supplier/monochrome/ouigo_vitesse_classique')
const Plans = svgLazyLoader('supplier/colored/plans')
const Railteam = svgLazyLoader('supplier/colored/railteam')
const RailteamMonochrome = svgLazyLoader('supplier/monochrome/railteam')
const RegionalMonochrome = svgLazyLoader('supplier/monochrome/regional')
const Remi = svgLazyLoader('supplier/colored/remi')
const RemiMonochrome = svgLazyLoader('supplier/monochrome/remi')
const Renfe = svgLazyLoader('supplier/colored/renfe')
const RenfeMonochrome = svgLazyLoader('supplier/monochrome/renfe')
const Sncb = svgLazyLoader('supplier/colored/sncb')
const SncbMonochrome = svgLazyLoader('supplier/monochrome/sncb')
const Sncf = svgLazyLoader('supplier/colored/sncf')
const SncfIntercitesMonochrome = svgLazyLoader('supplier/monochrome/sncf_intercites')
const SncfMonochrome = svgLazyLoader('supplier/monochrome/sncf')
const SncfVoyageurs = svgLazyLoader('supplier/colored/sncf_voyageurs')
const SpecialTrainMonochrome = svgLazyLoader('supplier/monochrome/special_train')
const Ter = svgLazyLoader('supplier/colored/ter')
const TerCarMonochrome = svgLazyLoader('supplier/monochrome/ter_car')
const TerMonochrome = svgLazyLoader('supplier/monochrome/ter')
const TergvMonochrome = svgLazyLoader('supplier/monochrome/tergv')
const Tgv = svgLazyLoader('supplier/colored/tgv')
const TgvInoui = svgLazyLoader('supplier/colored/tgv_inoui')
const TgvInouiMonochrome = svgLazyLoader('supplier/monochrome/tgv_inoui')
const TgvLyria = svgLazyLoader('supplier/colored/tgv_lyria')
const TgvLyriaMonochrome = svgLazyLoader('supplier/monochrome/tgv_lyria')
const TgvMonochrome = svgLazyLoader('supplier/monochrome/tgv')
const Trenitalia = svgLazyLoader('supplier/colored/trenitalia')
const TrenitaliaMonochrome = svgLazyLoader('supplier/monochrome/trenitalia')
const UnRienCestTout = svgLazyLoader('supplier/colored/un_rien_cest_tout')
const UnRienCestToutMonochrome = svgLazyLoader('supplier/monochrome/un_rien_cest_tout')
const Velib = svgLazyLoader('supplier/colored/velib')
const VelibMonochrome = svgLazyLoader('supplier/monochrome/velib')
const Zou = svgLazyLoader('supplier/colored/zou')
const ZouMonochrome = svgLazyLoader('supplier/monochrome/zou')

const ALL_SUPPLIER_ICONS = [
  'fluo',
  'noctilien',
  'accor',
  'aleop',
  'aleop_monochrome',
  'allianz_travel',
  'ave_renfe',
  'ave_renfe_monochrome',
  'avis',
  'avis_budget',
  'avis_budget_monochrome',
  'avis_monochrome',
  'blablabus',
  'blablabus_monochrome',
  'blablacar',
  'blablacar_monochrome',
  'car_reservation_monochrome',
  'cff',
  'cff_ic',
  'cff_ic_monochrome',
  'cff_ir',
  'cff_ir_monochrome',
  'cff_monochrome',
  'cff_re',
  'cff_re_monochrome',
  'cfl',
  'cfl_monochrome',
  'city',
  'dark_chauffeur_inoui',
  'dark_coeur_inoui',
  'dark_confort_inoui',
  'dark_fleche_inoui',
  'dark_junior_et_cie_inoui',
  'dark_le_bar_inoui',
  'dark_location_inoui',
  'dark_mes_bagages_inoui',
  'dark_tgv',
  'dark_wifi_inoui',
  'db',
  'db_monochrome',
  'db_sncf',
  'db_sncf_monochrome',
  'eurocity',
  'eurocity_monochrome',
  'eurostar',
  'eurostar_monochrome',
  'flixbus',
  'flixbus_monochrome',
  'fluo_monochrome',
  'frecciarossa',
  'frecciarossa_monochrome',
  'google_maps',
  'google_maps_monochrome',
  'grande_vitesse_monochrome',
  'ice',
  'ice_monochrome',
  'intercites',
  'intercites_de_nuit',
  'intercites_de_nuit_monochrome',
  'intercites_monochrome',
  'international_monochrome',
  'lime',
  'lio',
  'lio_monochrome',
  'ma_location_avis',
  'ma_location_avis_monochrome',
  'mobigo',
  'mobigo_monochrome',
  'navette_monochrome',
  'nederlandse_spoorwegen',
  'nederlandse_spoorwegen_monochrome',
  'nightjet',
  'nightjet_monochrome',
  'nomad',
  'nomad_monochrome',
  'novotel',
  'obb',
  'obb_monochrome',
  'osmand',
  'osmand_monochrome',
  'ouigo_espagne_monochrome',
  'ouigo_grande_vitesse',
  'ouigo_grande_vitesse_monochrome',
  'ouigo_vitesse_classique',
  'ouigo_vitesse_classique_monochrome',
  'plans',
  'railteam',
  'railteam_monochrome',
  'regional_monochrome',
  'remi',
  'remi_monochrome',
  'renfe',
  'renfe_monochrome',
  'sncb',
  'sncb_monochrome',
  'sncf',
  'sncf_intercites_monochrome',
  'sncf_monochrome',
  'sncf_voyageurs',
  'special_train_monochrome',
  'ter',
  'ter_car_monochrome',
  'ter_monochrome',
  'tergv_monochrome',
  'tgv',
  'tgv_inoui',
  'tgv_inoui_monochrome',
  'tgv_lyria',
  'tgv_lyria_monochrome',
  'tgv_monochrome',
  'trenitalia',
  'trenitalia_monochrome',
  'un_rien_cest_tout',
  'un_rien_cest_tout_monochrome',
  'velib',
  'velib_monochrome',
  'zou',
  'zou_monochrome',
] as const
export type SupplierIcon = (typeof ALL_SUPPLIER_ICONS)[number]

const ICONS: LazyIconsGroup<SupplierIcon> = {
  fluo: Fluo,
  noctilien: Noctilien,
  accor: Accor,
  aleop: Aleop,
  aleop_monochrome: AleopMonochrome,
  allianz_travel: AllianzTravel,
  ave_renfe: AveRenfe,
  ave_renfe_monochrome: AveRenfeMonochrome,
  avis: Avis,
  avis_budget: AvisBudget,
  avis_budget_monochrome: AvisBudgetMonochrome,
  avis_monochrome: AvisMonochrome,
  blablabus: Blablabus,
  blablabus_monochrome: BlablabusMonochrome,
  blablacar: Blablacar,
  blablacar_monochrome: BlablacarMonochrome,
  car_reservation_monochrome: CarReservationMonochrome,
  cff: Cff,
  cff_ic: CffIc,
  cff_ic_monochrome: CffIcMonochrome,
  cff_ir: CffIr,
  cff_ir_monochrome: CffIrMonochrome,
  cff_monochrome: CffMonochrome,
  cff_re: CffRe,
  cff_re_monochrome: CffReMonochrome,
  cfl: Cfl,
  cfl_monochrome: CflMonochrome,
  city: City,
  dark_chauffeur_inoui: DarkChauffeurInoui,
  dark_coeur_inoui: DarkCoeurInoui,
  dark_confort_inoui: DarkConfortInoui,
  dark_fleche_inoui: DarkFlecheInoui,
  dark_junior_et_cie_inoui: DarkJuniorEtCieInoui,
  dark_le_bar_inoui: DarkLeBarInoui,
  dark_location_inoui: DarkLocationInoui,
  dark_mes_bagages_inoui: DarkMesBagagesInoui,
  dark_tgv: DarkTgv,
  dark_wifi_inoui: DarkWifiInoui,
  db: Db,
  db_monochrome: DbMonochrome,
  db_sncf: DbSncf,
  db_sncf_monochrome: DbSncfMonochrome,
  eurocity: Eurocity,
  eurocity_monochrome: EurocityMonochrome,
  eurostar: Eurostar,
  eurostar_monochrome: EurostarMonochrome,
  flixbus: Flixbus,
  flixbus_monochrome: FlixbusMonochrome,
  fluo_monochrome: FluoMonochrome,
  frecciarossa: Frecciarossa,
  frecciarossa_monochrome: FrecciarossaMonochrome,
  google_maps: GoogleMaps,
  google_maps_monochrome: GoogleMapsMonochrome,
  grande_vitesse_monochrome: GrandeVitesseMonochrome,
  ice: Ice,
  ice_monochrome: IceMonochrome,
  intercites: Intercites,
  intercites_de_nuit: IntercitesDeNuit,
  intercites_de_nuit_monochrome: IntercitesDeNuitMonochrome,
  intercites_monochrome: IntercitesMonochrome,
  international_monochrome: InternationalMonochrome,
  lime: Lime,
  lio: Lio,
  lio_monochrome: LioMonochrome,
  ma_location_avis: MaLocationAvis,
  ma_location_avis_monochrome: MaLocationAvisMonochrome,
  mobigo: Mobigo,
  mobigo_monochrome: MobigoMonochrome,
  navette_monochrome: NavetteMonochrome,
  nederlandse_spoorwegen: NederlandseSpoorwegen,
  nederlandse_spoorwegen_monochrome: NederlandseSpoorwegenMonochrome,
  nightjet: Nightjet,
  nightjet_monochrome: NightjetMonochrome,
  nomad: Nomad,
  nomad_monochrome: NomadMonochrome,
  novotel: Novotel,
  obb: Obb,
  obb_monochrome: ObbMonochrome,
  osmand: Osmand,
  osmand_monochrome: OsmandMonochrome,
  ouigo_espagne_monochrome: OuigoEspagneMonochrome,
  ouigo_grande_vitesse: OuigoGrandeVitesse,
  ouigo_grande_vitesse_monochrome: OuigoGrandeVitesseMonochrome,
  ouigo_vitesse_classique: OuigoVitesseClassique,
  ouigo_vitesse_classique_monochrome: OuigoVitesseClassiqueMonochrome,
  plans: Plans,
  railteam: Railteam,
  railteam_monochrome: RailteamMonochrome,
  regional_monochrome: RegionalMonochrome,
  remi: Remi,
  remi_monochrome: RemiMonochrome,
  renfe: Renfe,
  renfe_monochrome: RenfeMonochrome,
  sncb: Sncb,
  sncb_monochrome: SncbMonochrome,
  sncf: Sncf,
  sncf_intercites_monochrome: SncfIntercitesMonochrome,
  sncf_monochrome: SncfMonochrome,
  sncf_voyageurs: SncfVoyageurs,
  special_train_monochrome: SpecialTrainMonochrome,
  ter: Ter,
  ter_car_monochrome: TerCarMonochrome,
  ter_monochrome: TerMonochrome,
  tergv_monochrome: TergvMonochrome,
  tgv: Tgv,
  tgv_inoui: TgvInoui,
  tgv_inoui_monochrome: TgvInouiMonochrome,
  tgv_lyria: TgvLyria,
  tgv_lyria_monochrome: TgvLyriaMonochrome,
  tgv_monochrome: TgvMonochrome,
  trenitalia: Trenitalia,
  trenitalia_monochrome: TrenitaliaMonochrome,
  un_rien_cest_tout: UnRienCestTout,
  un_rien_cest_tout_monochrome: UnRienCestToutMonochrome,
  velib: Velib,
  velib_monochrome: VelibMonochrome,
  zou: Zou,
  zou_monochrome: ZouMonochrome,
}

const isSupplierIcon = (supplierKey: string): supplierKey is SupplierIcon =>
  ALL_SUPPLIER_ICONS.includes(supplierKey as SupplierIcon)

const getSupplierIcon = (
  supplierKey: string | undefined,
  icons: LazyIconsGroup<SupplierIcon> = ICONS
): LazyIcon | undefined => (supplierKey && isSupplierIcon(supplierKey) ? icons[supplierKey] : undefined)

export const getSupplierIcons = (): LazyIconsGroup<SupplierIcon> => ICONS

export const getSupplierColoredIcon = (supplierKey: string | undefined): LazyIcon | undefined => {
  const lowerCaseSupplierKey = supplierKey?.toLowerCase()

  return getSupplierIcon(lowerCaseSupplierKey)
}

export const getSupplierMonochromeIcon = (supplierKey: string | undefined): LazyIcon | undefined => {
  const lowerCaseSupplierKey = `${supplierKey?.toLowerCase()}_monochrome`

  return getSupplierIcon(lowerCaseSupplierKey)
}

export const getSupplierOnDarkIcon = (supplierKey: string | undefined): LazyIcon | undefined => {
  const lowerCaseSupplierKey = `dark_${supplierKey?.toLowerCase()}`

  return getSupplierIcon(lowerCaseSupplierKey)
}
