import { ComponentType, FC, ReactNode, useEffect, useRef } from 'react'
import { SerializedStyles } from '@emotion/react'
import { Dialog as MaterialDialog, DialogProps as MaterialDialogProps, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import ExternalLink from '@Components/designSystem/forms/links/externalLink/ExternalLink'
import LinkButton from '@Components/designSystem/forms/links/linkButton/LinkButton'
import MomentSystemError from '@Components/designSystem/illustrations/moment/systemError/MomentSystemError'
import ConditionalWrapper from '@Components/utils/wrapper/ConditionalWrapper'
import { useIsMediumUp } from '@Hooks/useResponsive'

import { DialogAction } from './Dialog.types'
import * as styles from './styles'

type DialogVariant =
  | { variant?: 'default'; illustration?: ComponentType }
  | { variant?: 'error_system'; illustration?: never }

export type DialogProps = DialogVariant & {
  show?: boolean
  message: string
  annotations?: ReactNode
  annotationsStyles?: SerializedStyles
  defaultAction: DialogAction
  actions?: [DialogAction] | [DialogAction, DialogAction]
  onClose: VoidFunction
  hasToDisableCloseOnBackdropClick?: boolean
  hasToDisableCloseOnEscapeKeyDown?: boolean
  hasToDisplayActionsInColumnOnMobile?: boolean
} & Omit<MaterialDialogProps, 'open' | 'onClose'>

const Dialog: FC<DialogProps> = ({
  show = false,
  message,
  annotations,
  annotationsStyles,
  variant = 'default',
  illustration: Illustration = variant === 'error_system' && MomentSystemError,
  defaultAction,
  actions = [],
  onClose,
  hasToDisableCloseOnBackdropClick = false,
  hasToDisableCloseOnEscapeKeyDown = false,
  hasToDisplayActionsInColumnOnMobile = false,
  ...rest
}) => {
  const theme = useTheme()
  const isDesktop = useIsMediumUp(theme)

  const dialogRef = useRef<HTMLDivElement>(null)

  const handleClose = (reason: string) => {
    if (
      (reason === 'backdropClick' && hasToDisableCloseOnBackdropClick) ||
      (reason === 'escapeKeyDown' && hasToDisableCloseOnEscapeKeyDown)
    ) {
      return
    }

    onClose()
  }

  const shouldDisplayActionsInColumnOnMobile =
    (hasToDisplayActionsInColumnOnMobile || actions.length >= 2) && !isDesktop

  useEffect(() => {
    dialogRef.current?.focus()
  }, [])

  return (
    <MaterialDialog
      {...rest}
      PaperProps={{ elevation: 0 }}
      open={show}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) => handleClose(reason)}
      data-test="dialog"
    >
      <div css={styles.content} ref={dialogRef} tabIndex={-1}>
        {Illustration && <Illustration css={styles.illustration(theme)} />}
        <Typography variant="body1" data-test="message">
          {message}
        </Typography>
        {annotations && (
          <Typography css={annotationsStyles || styles.annotations(theme)} data-test="annotations" component="span">
            {annotations}
          </Typography>
        )}
        <div css={styles.actions}>
          <div css={styles.linksWrapper(shouldDisplayActionsInColumnOnMobile)}>
            <ConditionalWrapper
              condition={!!defaultAction.href}
              renderWrapper={(children) => (
                <ExternalLink
                  data-test="default-action"
                  href={defaultAction.href}
                  onClick={() => defaultAction.onClick?.()}
                >
                  {children}
                </ExternalLink>
              )}
              renderDefaultWrapper={(children) => (
                <LinkButton
                  data-test="default-action"
                  onClick={() => {
                    defaultAction.onClick?.()
                    onClose()
                  }}
                  form={defaultAction.form}
                >
                  {children}
                </LinkButton>
              )}
            >
              {defaultAction.label}
            </ConditionalWrapper>
            {actions.map(({ href, onClick, form, label }) => (
              <ConditionalWrapper
                key={label}
                condition={!!href}
                renderWrapper={(children) => (
                  <ExternalLink data-test="next-action" href={href} onClick={() => onClick?.()}>
                    {children}
                  </ExternalLink>
                )}
                renderDefaultWrapper={(children) => (
                  <LinkButton
                    data-test="next-action"
                    onClick={() => {
                      onClick?.()
                      onClose()
                    }}
                    form={form}
                  >
                    {children}
                  </LinkButton>
                )}
              >
                {label}
              </ConditionalWrapper>
            ))}
          </div>
        </div>
      </div>
    </MaterialDialog>
  )
}

export default Dialog
