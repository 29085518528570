import { accordionClasses } from '@mui/material/Accordion'
import { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

import { SPACING_2 } from '@Styles/constants/spacing'

export const overridesAccordion = (): ComponentsOverrides['MuiAccordion'] => ({
  root: {
    [`&.${accordionClasses.expanded}`]: {
      margin: 0,
    },
    [`&.${accordionClasses.root}::before`]: {
      display: 'none',
    },
  },
  rounded: {
    borderRadius: 0,
  },
})

export const overridesAccordionSummary = (): ComponentsOverrides['MuiAccordionSummary'] => ({
  root: {
    padding: SPACING_2,
    // Minimal height without expanded icon
    minHeight: rem(56),
    [`&.${accordionSummaryClasses.expanded}`]: {
      // Minimal height without expanded icon
      minHeight: rem(56),
    },
  },
  content: {
    margin: 0,
    [`&.${accordionSummaryClasses.expanded}`]: {
      margin: 0,
    },
  },
  expandIconWrapper: {
    marginRight: `-${SPACING_2}`,
    padding: `0 ${SPACING_2}`,
  },
})

export const overridesAccordionDetails = (): ComponentsOverrides['MuiAccordionDetails'] => ({
  root: {
    padding: 0,
  },
})
