import { forwardRef, ForwardRefRenderFunction, memo } from 'react'
import { Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import {
  HighlightedPlace,
  IconSource,
  RecentSearchPlace,
  ShortItineraryIcon,
  TransporterCode,
} from 'invictus-sdk-typescript'

import { getDsIcon } from '@Components/designSystem/icons/dsIcons'
import TransportIcon from '@Components/designSystem/icons/transports/TransportIcon'
import { AutocompleteItemProps } from '@Components/designSystem/navigation/search/autocomplete/Autocomplete.types'
import VisuallyHiddenLabel from '@Components/utils/aria/VisuallyHiddenLabel'

import * as commonStyles from '../../common/styles'
import * as mainStyles from '../styles'

import * as styles from './styles'

export type RecentSearchPlaceExtended = RecentSearchPlace & {
  description?: string
  city?: string
  codes?: TransporterCode[]
}
type AutocompleteInputProposalModel = HighlightedPlace | RecentSearchPlaceExtended

export type AutocompleteInputProposalResultProps = AutocompleteItemProps<AutocompleteInputProposalModel>

// On évite que le background de l'icône se confonde avec le background de l'Autocomplete
const transformGeneratedBackground = (theme: Theme, lineIcon: ShortItineraryIcon): ShortItineraryIcon =>
  lineIcon.iconSource === IconSource.GENERATE &&
  lineIcon.generate &&
  ['FFF', 'FFFFFF', ''].includes(lineIcon.generate.color || '')
    ? {
        ...lineIcon,
        generate: { ...lineIcon.generate, color: (theme.customPalette.onElements.elevated || '').replace('#', '') },
      }
    : lineIcon

const AutocompleteInputProposalResult: ForwardRefRenderFunction<
  HTMLDivElement,
  AutocompleteInputProposalResultProps
> = ({ model, isHighlighted }, ref) => {
  const theme = useTheme()

  const {
    label,
    type: { label: placeName, iconId },
    description = '',
    linesIcons,
  } = model
  const PlaceIcon = getDsIcon(iconId)

  return (
    <div ref={ref} css={commonStyles.autocompleteResult(theme, isHighlighted)}>
      {PlaceIcon && <PlaceIcon css={[mainStyles.autocompleteResultPlaceIconContainer, mainStyles.placeIcon(theme)]} />}
      <div css={mainStyles.autocompleteResultPlaceData}>
        <Typography variant="caption" color="textSecondary">
          {placeName}
          <VisuallyHiddenLabel label=" : " />
        </Typography>
        <span>
          <Typography variant="body1" component="span">
            {label}
          </Typography>
          {description && (
            <Typography component="span" color="textSecondary">
              {` (${description})`}
            </Typography>
          )}
        </span>
        {linesIcons.length > 0 && (
          <span css={styles.autocompleteResultLinesContainer}>
            {linesIcons.map((lineIcon) => (
              <TransportIcon
                key={`${lineIcon.label}${lineIcon.name}${lineIcon.generate?.color || ''}`}
                {...transformGeneratedBackground(theme, lineIcon)}
                isSmallGenerated
              />
            ))}
          </span>
        )}
      </div>
    </div>
  )
}

export default memo(forwardRef(AutocompleteInputProposalResult))
