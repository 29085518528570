import { FC, SyntheticEvent } from 'react'
import {
  Snackbar as MuiSnackbar,
  SnackbarCloseReason,
  SnackbarProps as MuiSnackbarProps,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import VisuallyHiddenLabel from '@Components/utils/aria/VisuallyHiddenLabel'
import { useSeverityIcon } from '@Hooks/useSeverityIcon'

import { SnackbarMessage } from './Snackbar.types'
import * as styles from './styles'

const AUTO_HIDE_DURATION = 5_000

export type SnackbarProps = Omit<MuiSnackbarProps, 'onClose' | 'open' | 'message'> & {
  isOpen?: boolean
  onClose?: VoidFunction
  message?: SnackbarMessage
}

const Snackbar: FC<SnackbarProps> = ({
  isOpen = false,
  message,
  onClose,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  autoHideDuration = AUTO_HIDE_DURATION,
  ...rest
}) => {
  const theme = useTheme()
  const { icon: SeverityIcon, ariaLabel } = useSeverityIcon(message?.severity)

  if (!message) {
    return null
  }

  const Icon = message.icon || SeverityIcon

  const handleClose = (_: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    message.onClose?.()
    onClose?.()
  }

  return (
    <div id={message.key} role="alert">
      <MuiSnackbar
        {...rest}
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        key={message.key}
        open={isOpen}
        onClose={handleClose}
      >
        <div css={styles.content(theme)}>
          {Icon && (
            <>
              <span css={styles.icon(theme, message.severity)} aria-hidden>
                <Icon />
              </span>
              {ariaLabel && <VisuallyHiddenLabel label={ariaLabel} />}
            </>
          )}

          <Typography component="span">{message.content}</Typography>

          {/* TODO The button is hidden because the message disappears by itself, otherwise remove the aria-hidden attribute */}
          {!!message.action && (
            <button type="button" css={styles.action(theme)} onClick={() => message.action?.onClick()} aria-hidden>
              <Typography component="span" variant="body1">
                {message.action.label}
              </Typography>
            </button>
          )}
        </div>
      </MuiSnackbar>
    </div>
  )
}

export default Snackbar
