import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { highContrast } from '@Styles/a11y'
import { SPACING_1, SPACING_2 } from '@Styles/constants/spacing'

export const placeIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.text.primary,
  })

export const autocompleteResult = (theme: Theme, isHighlighted: boolean): SerializedStyles =>
  css({
    ...(isHighlighted && {
      ...highContrast({
        border: 'solid 2px transparent',
      }),
      backgroundColor: theme.customPalette.onElements.selected,
    }),
    '& > * + *': {
      marginLeft: SPACING_2,
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    outline: 'none',
    padding: `${SPACING_1} ${SPACING_2}`,
    [theme.breakpoints.up('md')]: {
      padding: `${SPACING_1} ${SPACING_2}`,
    },
  })

export const autocompleteResultPlaceIconContainer = css({
  flexShrink: 0,
  ...size(rem(24)),
})

export const autocompleteResultPlaceData = css({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
})
