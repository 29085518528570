import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const overridesFormLabel = (theme: Theme): ComponentsOverrides['MuiFormLabel'] => ({
  root: {
    color: theme.palette.text.primary,
  },
  asterisk: {
    order: -1,
  },
})
