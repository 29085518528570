// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const RoissyBus = svgLazyLoader('transports/roissy_bus')
const Tvm = svgLazyLoader('transports/tvm')
const Boat = svgLazyLoader('transports/boat')
const Bus = svgLazyLoader('transports/bus')
const BusNantesC5 = svgLazyLoader('transports/bus_nantes_c5')
const Bus168 = svgLazyLoader('transports/bus_168')
const Bus194 = svgLazyLoader('transports/bus_194')
const Bus20 = svgLazyLoader('transports/bus_20')
const Bus21 = svgLazyLoader('transports/bus_21')
const Bus22 = svgLazyLoader('transports/bus_22')
const Bus24 = svgLazyLoader('transports/bus_24')
const Bus25 = svgLazyLoader('transports/bus_25')
const Bus251 = svgLazyLoader('transports/bus_251')
const Bus26 = svgLazyLoader('transports/bus_26')
const Bus27 = svgLazyLoader('transports/bus_27')
const Bus28 = svgLazyLoader('transports/bus_28')
const Bus29 = svgLazyLoader('transports/bus_29')
const Bus30 = svgLazyLoader('transports/bus_30')
const Bus31 = svgLazyLoader('transports/bus_31')
const Bus32 = svgLazyLoader('transports/bus_32')
const Bus320 = svgLazyLoader('transports/bus_320')
const Bus347 = svgLazyLoader('transports/bus_347')
const Bus35 = svgLazyLoader('transports/bus_35')
const Bus38 = svgLazyLoader('transports/bus_38')
const Bus39 = svgLazyLoader('transports/bus_39')
const Bus394 = svgLazyLoader('transports/bus_394')
const Bus40 = svgLazyLoader('transports/bus_40')
const Bus42 = svgLazyLoader('transports/bus_42')
const Bus43 = svgLazyLoader('transports/bus_43')
const Bus45 = svgLazyLoader('transports/bus_45')
const Bus46 = svgLazyLoader('transports/bus_46')
const Bus47 = svgLazyLoader('transports/bus_47')
const Bus48 = svgLazyLoader('transports/bus_48')
const Bus52 = svgLazyLoader('transports/bus_52')
const Bus54 = svgLazyLoader('transports/bus_54')
const Bus56 = svgLazyLoader('transports/bus_56')
const Bus57 = svgLazyLoader('transports/bus_57')
const Bus58 = svgLazyLoader('transports/bus_58')
const Bus59 = svgLazyLoader('transports/bus_59')
const Bus595 = svgLazyLoader('transports/bus_595')
const Bus60 = svgLazyLoader('transports/bus_60')
const Bus61 = svgLazyLoader('transports/bus_61')
const Bus62 = svgLazyLoader('transports/bus_62')
const BusDefault = svgLazyLoader('transports/bus_default')
const BusDirect2 = svgLazyLoader('transports/bus_direct_2')
const BusDirect3 = svgLazyLoader('transports/bus_direct_3')
const BusDirect4 = svgLazyLoader('transports/bus_direct_4')
const BusFront = svgLazyLoader('transports/bus_front')
const BusPc1 = svgLazyLoader('transports/bus_pc1')
const BusPc2 = svgLazyLoader('transports/bus_pc2')
const BusPc3 = svgLazyLoader('transports/bus_pc3')
const CableCar = svgLazyLoader('transports/cable_car')
const Car = svgLazyLoader('transports/car')
const CarBus = svgLazyLoader('transports/car_bus')
const Carpooling = svgLazyLoader('transports/carpooling')
const Ellipsis = svgLazyLoader('transports/ellipsis')
const Funicular = svgLazyLoader('transports/funicular')
const Metro1 = svgLazyLoader('transports/metro_1')
const Metro10 = svgLazyLoader('transports/metro_10')
const Metro11 = svgLazyLoader('transports/metro_11')
const Metro12 = svgLazyLoader('transports/metro_12')
const Metro13 = svgLazyLoader('transports/metro_13')
const Metro14 = svgLazyLoader('transports/metro_14')
const Metro15 = svgLazyLoader('transports/metro_15')
const Metro16 = svgLazyLoader('transports/metro_16')
const Metro17 = svgLazyLoader('transports/metro_17')
const Metro18 = svgLazyLoader('transports/metro_18')
const Metro2 = svgLazyLoader('transports/metro_2')
const Metro3 = svgLazyLoader('transports/metro_3')
const Metro3bis = svgLazyLoader('transports/metro_3bis')
const Metro4 = svgLazyLoader('transports/metro_4')
const Metro5 = svgLazyLoader('transports/metro_5')
const Metro6 = svgLazyLoader('transports/metro_6')
const Metro7 = svgLazyLoader('transports/metro_7')
const Metro7bis = svgLazyLoader('transports/metro_7bis')
const Metro8 = svgLazyLoader('transports/metro_8')
const Metro9 = svgLazyLoader('transports/metro_9')
const MetroCdgval = svgLazyLoader('transports/metro_cdgval')
const MetroEmpty = svgLazyLoader('transports/metro_empty')
const MetroFull = svgLazyLoader('transports/metro_full')
const MetroNantesA = svgLazyLoader('transports/metro_nantes_a')
const MetroOrlyval = svgLazyLoader('transports/metro_orlyval')
const N01 = svgLazyLoader('transports/n01')
const N02 = svgLazyLoader('transports/n02')
const N11 = svgLazyLoader('transports/n11')
const N12 = svgLazyLoader('transports/n12')
const N122 = svgLazyLoader('transports/n122')
const N123 = svgLazyLoader('transports/n123')
const N13 = svgLazyLoader('transports/n13')
const N130 = svgLazyLoader('transports/n130')
const N131 = svgLazyLoader('transports/n131')
const N132 = svgLazyLoader('transports/n132')
const N133 = svgLazyLoader('transports/n133')
const N134 = svgLazyLoader('transports/n134')
const N135 = svgLazyLoader('transports/n135')
const N137 = svgLazyLoader('transports/n137')
const N139 = svgLazyLoader('transports/n139')
const N14 = svgLazyLoader('transports/n14')
const N140 = svgLazyLoader('transports/n140')
const N141 = svgLazyLoader('transports/n141')
const N142 = svgLazyLoader('transports/n142')
const N143 = svgLazyLoader('transports/n143')
const N145 = svgLazyLoader('transports/n145')
const N146 = svgLazyLoader('transports/n146')
const N147 = svgLazyLoader('transports/n147')
const N148 = svgLazyLoader('transports/n148')
const N15 = svgLazyLoader('transports/n15')
const N150 = svgLazyLoader('transports/n150')
const N151 = svgLazyLoader('transports/n151')
const N152 = svgLazyLoader('transports/n152')
const N153 = svgLazyLoader('transports/n153')
const N154 = svgLazyLoader('transports/n154')
const N155 = svgLazyLoader('transports/n155')
const N16 = svgLazyLoader('transports/n16')
const N160 = svgLazyLoader('transports/n160')
const N161 = svgLazyLoader('transports/n161')
const N162 = svgLazyLoader('transports/n162')
const N21 = svgLazyLoader('transports/n21')
const N22 = svgLazyLoader('transports/n22')
const N23 = svgLazyLoader('transports/n23')
const N24 = svgLazyLoader('transports/n24')
const N31 = svgLazyLoader('transports/n31')
const N32 = svgLazyLoader('transports/n32')
const N33 = svgLazyLoader('transports/n33')
const N34 = svgLazyLoader('transports/n34')
const N35 = svgLazyLoader('transports/n35')
const N41 = svgLazyLoader('transports/n41')
const N42 = svgLazyLoader('transports/n42')
const N43 = svgLazyLoader('transports/n43')
const N44 = svgLazyLoader('transports/n44')
const N45 = svgLazyLoader('transports/n45')
const N51 = svgLazyLoader('transports/n51')
const N52 = svgLazyLoader('transports/n52')
const N53 = svgLazyLoader('transports/n53')
const N61 = svgLazyLoader('transports/n61')
const N62 = svgLazyLoader('transports/n62')
const N63 = svgLazyLoader('transports/n63')
const N66 = svgLazyLoader('transports/n66')
const N71 = svgLazyLoader('transports/n71')
const NoctilienLogo = svgLazyLoader('transports/noctilien_logo')
const Plane = svgLazyLoader('transports/plane')
const RerA = svgLazyLoader('transports/rer_a')
const RerB = svgLazyLoader('transports/rer_b')
const RerC = svgLazyLoader('transports/rer_c')
const RerD = svgLazyLoader('transports/rer_d')
const RerDefault = svgLazyLoader('transports/rer_default')
const RerE = svgLazyLoader('transports/rer_e')
const RerFull = svgLazyLoader('transports/rer_full')
const Taxi = svgLazyLoader('transports/taxi')
const TerCiti = svgLazyLoader('transports/ter_citi')
const TerCitiexpress = svgLazyLoader('transports/ter_citiexpress')
const TerKrono = svgLazyLoader('transports/ter_krono')
const TerKronoPlus = svgLazyLoader('transports/ter_krono_plus')
const TerProxi = svgLazyLoader('transports/ter_proxi')
const Train = svgLazyLoader('transports/train')
const TrainBus = svgLazyLoader('transports/train_bus')
const TrainCancelled = svgLazyLoader('transports/train_cancelled')
const TrainFull = svgLazyLoader('transports/train_full')
const Tramway1 = svgLazyLoader('transports/tramway_1')
const Tramway10 = svgLazyLoader('transports/tramway_10')
const Tramway11 = svgLazyLoader('transports/tramway_11')
const Tramway12 = svgLazyLoader('transports/tramway_12')
const Tramway13 = svgLazyLoader('transports/tramway_13')
const Tramway14 = svgLazyLoader('transports/tramway_14')
const Tramway2 = svgLazyLoader('transports/tramway_2')
const Tramway3a = svgLazyLoader('transports/tramway_3a')
const Tramway3b = svgLazyLoader('transports/tramway_3b')
const Tramway4 = svgLazyLoader('transports/tramway_4')
const Tramway5 = svgLazyLoader('transports/tramway_5')
const Tramway6 = svgLazyLoader('transports/tramway_6')
const Tramway7 = svgLazyLoader('transports/tramway_7')
const Tramway8 = svgLazyLoader('transports/tramway_8')
const Tramway9 = svgLazyLoader('transports/tramway_9')
const TramwayDefault = svgLazyLoader('transports/tramway_default')
const TransilienDefault = svgLazyLoader('transports/transilien_default')
const TransilienFull = svgLazyLoader('transports/transilien_full')
const TransilienH = svgLazyLoader('transports/transilien_h')
const TransilienJ = svgLazyLoader('transports/transilien_j')
const TransilienK = svgLazyLoader('transports/transilien_k')
const TransilienL = svgLazyLoader('transports/transilien_l')
const TransilienN = svgLazyLoader('transports/transilien_n')
const TransilienP = svgLazyLoader('transports/transilien_p')
const TransilienR = svgLazyLoader('transports/transilien_r')
const TransilienSimple = svgLazyLoader('transports/transilien_simple')
const TransilienU = svgLazyLoader('transports/transilien_u')
const TransilienV = svgLazyLoader('transports/transilien_v')
const TransportType = svgLazyLoader('transports/transport_type')
const Traverse = svgLazyLoader('transports/traverse')
const Vtc = svgLazyLoader('transports/vtc')

const ALL_TRANSPORTS_ICONS = [
  'roissy_bus',
  'tvm',
  'boat',
  'bus',
  'bus_nantes_c5',
  'bus_168',
  'bus_194',
  'bus_20',
  'bus_21',
  'bus_22',
  'bus_24',
  'bus_25',
  'bus_251',
  'bus_26',
  'bus_27',
  'bus_28',
  'bus_29',
  'bus_30',
  'bus_31',
  'bus_32',
  'bus_320',
  'bus_347',
  'bus_35',
  'bus_38',
  'bus_39',
  'bus_394',
  'bus_40',
  'bus_42',
  'bus_43',
  'bus_45',
  'bus_46',
  'bus_47',
  'bus_48',
  'bus_52',
  'bus_54',
  'bus_56',
  'bus_57',
  'bus_58',
  'bus_59',
  'bus_595',
  'bus_60',
  'bus_61',
  'bus_62',
  'bus_default',
  'bus_direct_2',
  'bus_direct_3',
  'bus_direct_4',
  'bus_front',
  'bus_pc1',
  'bus_pc2',
  'bus_pc3',
  'bus_square',
  'cable_car',
  'car',
  'car_bus',
  'carpooling',
  'ellipsis',
  'funicular',
  'metro_1',
  'metro_10',
  'metro_11',
  'metro_12',
  'metro_13',
  'metro_14',
  'metro_15',
  'metro_16',
  'metro_17',
  'metro_18',
  'metro_2',
  'metro_3',
  'metro_3bis',
  'metro_4',
  'metro_5',
  'metro_6',
  'metro_7',
  'metro_7bis',
  'metro_8',
  'metro_9',
  'metro_cdgval',
  'metro_empty',
  'metro_full',
  'metro_nantes_a',
  'metro_orlyval',
  'n01',
  'n02',
  'n11',
  'n12',
  'n122',
  'n123',
  'n13',
  'n130',
  'n131',
  'n132',
  'n133',
  'n134',
  'n135',
  'n137',
  'n139',
  'n14',
  'n140',
  'n141',
  'n142',
  'n143',
  'n145',
  'n146',
  'n147',
  'n148',
  'n15',
  'n150',
  'n151',
  'n152',
  'n153',
  'n154',
  'n155',
  'n16',
  'n160',
  'n161',
  'n162',
  'n21',
  'n22',
  'n23',
  'n24',
  'n31',
  'n32',
  'n33',
  'n34',
  'n35',
  'n41',
  'n42',
  'n43',
  'n44',
  'n45',
  'n51',
  'n52',
  'n53',
  'n61',
  'n62',
  'n63',
  'n66',
  'n71',
  'noctilien_logo',
  'plane',
  'rer_a',
  'rer_b',
  'rer_c',
  'rer_d',
  'rer_default',
  'rer_e',
  'rer_full',
  'taxi',
  'ter_citi',
  'ter_citiexpress',
  'ter_krono',
  'ter_krono+',
  'ter_proxi',
  'ter_square',
  'train',
  'train_bus',
  'train_cancelled',
  'train_full',
  'train_square',
  'tramway_1',
  'tramway_10',
  'tramway_11',
  'tramway_12',
  'tramway_13',
  'tramway_14',
  'tramway_2',
  'tramway_3a',
  'tramway_3b',
  'tramway_4',
  'tramway_5',
  'tramway_6',
  'tramway_7',
  'tramway_8',
  'tramway_9',
  'tramway_default',
  'transilien_default',
  'transilien_full',
  'transilien_h',
  'transilien_j',
  'transilien_k',
  'transilien_l',
  'transilien_n',
  'transilien_p',
  'transilien_r',
  'transilien_simple',
  'transilien_u',
  'transilien_v',
  'transport_type',
  'traverse',
  'vtc',
] as const
export type TransportsIcon = (typeof ALL_TRANSPORTS_ICONS)[number]

const isTransportsIcon = (transportsKey: string): transportsKey is TransportsIcon =>
  ALL_TRANSPORTS_ICONS.includes(transportsKey as TransportsIcon)

const ICONS: LazyIconsGroup<TransportsIcon> = {
  roissy_bus: RoissyBus,
  tvm: Tvm,
  boat: Boat,
  bus: Bus,
  bus_nantes_c5: BusNantesC5,
  bus_168: Bus168,
  bus_194: Bus194,
  bus_20: Bus20,
  bus_21: Bus21,
  bus_22: Bus22,
  bus_24: Bus24,
  bus_25: Bus25,
  bus_251: Bus251,
  bus_26: Bus26,
  bus_27: Bus27,
  bus_28: Bus28,
  bus_29: Bus29,
  bus_30: Bus30,
  bus_31: Bus31,
  bus_32: Bus32,
  bus_320: Bus320,
  bus_347: Bus347,
  bus_35: Bus35,
  bus_38: Bus38,
  bus_39: Bus39,
  bus_394: Bus394,
  bus_40: Bus40,
  bus_42: Bus42,
  bus_43: Bus43,
  bus_45: Bus45,
  bus_46: Bus46,
  bus_47: Bus47,
  bus_48: Bus48,
  bus_52: Bus52,
  bus_54: Bus54,
  bus_56: Bus56,
  bus_57: Bus57,
  bus_58: Bus58,
  bus_59: Bus59,
  bus_595: Bus595,
  bus_60: Bus60,
  bus_61: Bus61,
  bus_62: Bus62,
  bus_default: BusDefault,
  bus_direct_2: BusDirect2,
  bus_direct_3: BusDirect3,
  bus_direct_4: BusDirect4,
  bus_front: BusFront,
  bus_pc1: BusPc1,
  bus_pc2: BusPc2,
  bus_pc3: BusPc3,
  bus_square: Bus,
  cable_car: CableCar,
  car: Car,
  car_bus: CarBus,
  carpooling: Carpooling,
  ellipsis: Ellipsis,
  funicular: Funicular,
  metro_1: Metro1,
  metro_10: Metro10,
  metro_11: Metro11,
  metro_12: Metro12,
  metro_13: Metro13,
  metro_14: Metro14,
  metro_15: Metro15,
  metro_16: Metro16,
  metro_17: Metro17,
  metro_18: Metro18,
  metro_2: Metro2,
  metro_3: Metro3,
  metro_3bis: Metro3bis,
  metro_4: Metro4,
  metro_5: Metro5,
  metro_6: Metro6,
  metro_7: Metro7,
  metro_7bis: Metro7bis,
  metro_8: Metro8,
  metro_9: Metro9,
  metro_cdgval: MetroCdgval,
  metro_empty: MetroEmpty,
  metro_full: MetroFull,
  metro_nantes_a: MetroNantesA,
  metro_orlyval: MetroOrlyval,
  n01: N01,
  n02: N02,
  n11: N11,
  n12: N12,
  n122: N122,
  n123: N123,
  n13: N13,
  n130: N130,
  n131: N131,
  n132: N132,
  n133: N133,
  n134: N134,
  n135: N135,
  n137: N137,
  n139: N139,
  n14: N14,
  n140: N140,
  n141: N141,
  n142: N142,
  n143: N143,
  n145: N145,
  n146: N146,
  n147: N147,
  n148: N148,
  n15: N15,
  n150: N150,
  n151: N151,
  n152: N152,
  n153: N153,
  n154: N154,
  n155: N155,
  n16: N16,
  n160: N160,
  n161: N161,
  n162: N162,
  n21: N21,
  n22: N22,
  n23: N23,
  n24: N24,
  n31: N31,
  n32: N32,
  n33: N33,
  n34: N34,
  n35: N35,
  n41: N41,
  n42: N42,
  n43: N43,
  n44: N44,
  n45: N45,
  n51: N51,
  n52: N52,
  n53: N53,
  n61: N61,
  n62: N62,
  n63: N63,
  n66: N66,
  n71: N71,
  noctilien_logo: NoctilienLogo,
  plane: Plane,
  rer_a: RerA,
  rer_b: RerB,
  rer_c: RerC,
  rer_d: RerD,
  rer_default: RerDefault,
  rer_e: RerE,
  rer_full: RerFull,
  taxi: Taxi,
  ter_citi: TerCiti,
  ter_citiexpress: TerCitiexpress,
  ter_krono: TerKrono,
  'ter_krono+': TerKronoPlus,
  ter_proxi: TerProxi,
  ter_square: Train,
  train: Train,
  train_bus: TrainBus,
  train_cancelled: TrainCancelled,
  train_full: TrainFull,
  train_square: Train,
  tramway_1: Tramway1,
  tramway_10: Tramway10,
  tramway_11: Tramway11,
  tramway_12: Tramway12,
  tramway_13: Tramway13,
  tramway_14: Tramway14,
  tramway_2: Tramway2,
  tramway_3a: Tramway3a,
  tramway_3b: Tramway3b,
  tramway_4: Tramway4,
  tramway_5: Tramway5,
  tramway_6: Tramway6,
  tramway_7: Tramway7,
  tramway_8: Tramway8,
  tramway_9: Tramway9,
  tramway_default: TramwayDefault,
  transilien_default: TransilienDefault,
  transilien_full: TransilienFull,
  transilien_h: TransilienH,
  transilien_j: TransilienJ,
  transilien_k: TransilienK,
  transilien_l: TransilienL,
  transilien_n: TransilienN,
  transilien_p: TransilienP,
  transilien_r: TransilienR,
  transilien_simple: TransilienSimple,
  transilien_u: TransilienU,
  transilien_v: TransilienV,
  transport_type: TransportType,
  traverse: Traverse,
  vtc: Vtc,
}

export const getTransportsIcon = (transportsKey: string | undefined): LazyIcon | undefined =>
  transportsKey && isTransportsIcon(transportsKey) ? ICONS[transportsKey] : undefined

export const getTransportsIcons = (): LazyIconsGroup<TransportsIcon> => ICONS
