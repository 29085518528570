import { sliderClasses } from '@mui/material/Slider'
import { alpha, Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem, size } from 'polished'

import { HALF_RADIUS } from '@Styles/constants/radius'

export const overridesSlider = (theme: Theme): ComponentsOverrides['MuiSlider'] => ({
  root: {
    [`&.${sliderClasses.disabled}`]: {
      [`& .${sliderClasses.rail}`]: {
        color: alpha(theme.customPalette.onElements.ripple || '', 0.3),
      },
      [`& .${sliderClasses.track}`]: {
        color: theme.palette.text.disabled,
      },
    },
    height: rem(7),
  },
  rail: {
    borderRadius: HALF_RADIUS,
    color: theme.palette.text.secondary,
    opacity: 1,
  },
  thumb: {
    ...size(rem(24)),
    [`&:hover, &.${sliderClasses.focusVisible}, &.${sliderClasses.active}`]: {
      boxShadow: `0 0 0 ${rem(14)} ${alpha(theme.customPalette.onElements.selected || '', 0.5)}`,
    },
    [`&.${sliderClasses.disabled}`]: {
      color: theme.palette.text.disabled,
    },
    color: theme.palette.primary.main,
  },
  track: {
    borderRadius: HALF_RADIUS,
    border: 'none',
    color: theme.palette.primary.main,
  },
})
