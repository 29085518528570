import { FC, ReactNode } from 'react'
import { IntlProvider } from 'react-intl'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { DEFAULT_LOCALE } from '@Constants/locale'
import { FeaturesProvider } from '@Contexts/features/FeaturesContext'
import { useAllMessages } from '@Hooks/i18n'
import { Features } from '@Types/features'
import Locale from '@Types/locale'
import { QUERY_KEY } from '@Types/queryKey'

const WidgetsCommonProviders: FC<{ locale?: Locale; children: ReactNode }> = ({
  locale = DEFAULT_LOCALE,
  children,
}) => {
  const { messages } = useAllMessages(locale)

  const { data: featuresStates, isError }: UseQueryResult<Features> = useQuery([QUERY_KEY.featuresStates], async () => {
    const response = await fetch(`/app/api/nextjs/feature-states`)

    return response.json()
  })

  if (!featuresStates && !isError) {
    return null
  }

  return (
    <FeaturesProvider features={featuresStates}>
      <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </FeaturesProvider>
  )
}

export default WidgetsCommonProviders
