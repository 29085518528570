import { css } from '@emotion/react'
import { rem } from 'polished'

import { SPACING_1 } from '@Styles/constants/spacing'

export const autocompleteResultLinesContainer = css({
  '& > span, svg': {
    margin: 0,
    marginLeft: SPACING_1,
  },
  '& > svg': {
    verticalAlign: 'middle',
    width: rem(20),
  },
  marginLeft: `-${SPACING_1}`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
