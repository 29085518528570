import { HTMLAttributes, ReactElement } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { LazyIcon, SVGIcon } from '@Components/designSystem/icons/icons.types'
import { getColor } from '@Components/designSystem/illustrations/Illustration.utils'
import { AuthorizedColor } from '@Components/designSystem/illustrations/types'

import * as styles from './styles'

type MomentBaseProps<ColorType extends AuthorizedColor> = {
  desktopIllustration: LazyIcon | SVGIcon
  illustration: LazyIcon | SVGIcon
  defaultColorName: ColorType
  colorName?: ColorType
}

const MomentBase = <ColorType extends AuthorizedColor>({
  desktopIllustration: DesktopIllustration,
  illustration: Illustration,
  defaultColorName,
  colorName,
  ...rest
}: MomentBaseProps<ColorType> & HTMLAttributes<HTMLDivElement>): ReactElement | null => {
  const theme = useTheme()

  return (
    <div {...rest} css={styles.illustration(theme, getColor(theme, colorName || defaultColorName))}>
      {/* TODO: IVTS-48423 ameliorer le SSR */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <DesktopIllustration />
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Illustration />
      </Box>
    </div>
  )
}

export default MomentBase
