import { CSSProperties } from 'react'
import { css, CSSObject, Keyframes, keyframes, SerializedStyles } from '@emotion/react'
import { buttonBaseClasses, touchRippleClasses } from '@mui/material/ButtonBase'
import { iconButtonClasses } from '@mui/material/IconButton'
import { inputBaseClasses } from '@mui/material/InputBase'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { getBackgrounds, getHighlightBackgrounds } from '@Components/designSystem/components/tag/styles'
import { HighlightCardVariant } from '@Components/designSystem/foundations/shapes/card/types'
import { typographyOverrides } from '@Material/typography'
import { highContrast } from '@Styles/a11y'
import { HALF_RADIUS } from '@Styles/constants/radius'
import { SPACING_0, SPACING_1, SPACING_2 } from '@Styles/constants/spacing'
import { getFocusVisibleIconButtonStyle, getFocusVisibleStyle } from '@Styles/focus'

export const prefix = (theme: Theme, disabled?: boolean): SerializedStyles =>
  css({
    color: theme.palette.text[disabled ? 'disabled' : 'secondary'],
    marginRight: SPACING_0,
  })

const visible: CSSObject = {
  position: 'initial',
  zIndex: 1,
}

export const inputPlace = (theme: Theme, disabled?: boolean): SerializedStyles =>
  css({
    ':focus-within [class*=MuiInputAdornment-positionEnd]': visible,
    [`.${inputBaseClasses.disabled}`]: {
      backgroundColor: 'inherit',
      borderColor: 'inherit',
    },
    [`.${inputBaseClasses.input}`]: {
      color: theme.palette.text[disabled ? 'disabled' : 'primary'],
      height: rem(44),
      padding: 0,
      textOverflow: 'ellipsis',
    },
    [`.${inputBaseClasses.input}::placeholder`]: {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
    caretColor: theme.palette.primary.main,
    color: theme.palette.text[disabled ? 'disabled' : 'secondary'],
    width: '100%',
    ...(typographyOverrides(theme)?.body2 as CSSProperties),
  })

export const suffix = css({
  ':hover': {
    ...visible,
  },
  outline: 'none',
  zIndex: -1,
})

export const buttonFocus = (theme: Theme, isFocused = false): SerializedStyles =>
  css({
    ...(isFocused && {
      '&:focus-visible::before': getFocusVisibleIconButtonStyle(theme.palette.primary.dark, `-${rem(7)}`),
    }),
  })

export const removeButton = (theme: Theme): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleIconButtonStyle(theme.palette.primary.dark, rem(-4)),
    ':hover, :focus, :active': {
      '& svg': {
        fill: theme.palette.primary.dark,
      },
      backgroundColor: 'transparent',
    },
    backgroundColor: theme.palette.text.secondary,
    padding: 0,
    svg: {
      ...size(rem(24)),
      fill: theme.palette.background.default,
      // On triche avec un margin négatif pour pouvoir mettre un background sur le bouton
      // qui ne dépasse pas du svg et contrer son padding interne
      margin: rem(-3),
    },
    ...size(rem(18)),
  })

export const swapButton: (theme: Theme) => SerializedStyles = (theme) => {
  const backgroundColor = theme.palette.background.default
  const OUTLINE_CONTENT_CLIP = '4px'
  const OUTLINE_BORDER_SIZE = '2px'

  return css({
    backgroundColor,
    padding: 0,
    ...size(rem(40)),
    [`&.${iconButtonClasses.root}.${iconButtonClasses.disabled}`]: {
      backgroundColor,
    },
    [`&.${iconButtonClasses.root}.${buttonBaseClasses.focusVisible} .${touchRippleClasses.root}`]: {
      display: 'none',
    },
    '&::after': {
      borderRadius: 'inherit',
      content: '""',
      position: 'absolute',
      ...size(`calc(100% - ${OUTLINE_CONTENT_CLIP})`),
    },
    '&:focus-visible': {
      boxShadow: 'none',
    },
    '&:focus-visible::after': {
      border: `${OUTLINE_BORDER_SIZE} solid ${theme.palette.primary.contrastText}`,
    },
    '&:hover, &:active, &:focus-visible': {
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
      backgroundColor: theme.palette.primary.dark,
    },
  })
}

export const swapIcon: (
  theme: Theme,
  animationPlayState: AnimationPlayState,
  isHorizontal?: boolean,
  disabled?: boolean
) => SerializedStyles = (theme, animationPlayState, isHorizontal, disabled) => {
  const initialRotation = isHorizontal ? 0 : 90
  const rotate: Keyframes = keyframes({
    from: { transform: `rotate(${initialRotation}deg)` },
    to: { transform: `rotate(${initialRotation + 180}deg)` },
  })

  return css({
    animation: `${rotate} 0.5s ${animationPlayState}`,
    fill: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    ...highContrast({
      fill: 'currentColor',
    }),
    transform: `rotate(${initialRotation}deg)`,
    ...size(rem(24)),
  })
}

export const tagButton = (theme: Theme, variant: HighlightCardVariant, isHighlighted: boolean): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleStyle(theme.palette.primary.contrastText, HALF_RADIUS),
    '&:hover, &:focus, &:focus:not(:focus-visible)': {
      background: getHighlightBackgrounds(theme, variant),
    },
    alignItems: 'center',
    background: getBackgrounds(theme, variant),
    borderRadius: HALF_RADIUS,
    cursor: 'pointer',
    display: 'flex',
    maxWidth: 'none',
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    ...(isHighlighted && {
      '&::before': getFocusVisibleStyle(theme.palette.primary.contrastText, HALF_RADIUS),
      background: getHighlightBackgrounds(theme, variant),
    }),
  })

export const fullTextSuggestionsSection = (theme: Theme): SerializedStyles =>
  css({
    alignItems: 'center',
    margin: `0 0 ${SPACING_2} ${SPACING_2}`,
    [theme.breakpoints.up('md')]: {
      margin: `0 ${SPACING_2} ${SPACING_2} ${SPACING_2}`,
    },
  })

export const fullTextSuggestionsSections = (theme: Theme): SerializedStyles =>
  css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${SPACING_1} 0 ${SPACING_2}`,
    [theme.breakpoints.up('md')]: {
      marginTop: SPACING_2,
    },
  })

export const fullTextSuggestionsTagButtons = (theme: Theme): SerializedStyles =>
  css({
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: SPACING_1,
      marginRight: SPACING_1,
    },
  })

export const fullTextSuggestionsTitle = (theme: Theme): SerializedStyles =>
  css({
    marginBottom: SPACING_1,
    [theme.breakpoints.up('md')]: {
      marginBottom: SPACING_2,
    },
  })

export const catalogAutocompleteResultImg = css({
  ...size(rem(40)),
  flexShrink: 0,
})

export const autocompleteResultHighlight = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.primary,
  })
