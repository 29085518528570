// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const BankCard = svgLazyLoader('payment/bank_card')
const Calendar = svgLazyLoader('payment/calendar')
const CalendarAdd = svgLazyLoader('payment/calendar_add')
const CalendarCheck = svgLazyLoader('payment/calendar_check')
const CardDiscount = svgLazyLoader('payment/card_discount')
const Cart = svgLazyLoader('payment/cart')
const CartCryptogram = svgLazyLoader('payment/cart_cryptogram')
const CollectAtStation = svgLazyLoader('payment/collect_at_station')
const Ebillet = svgLazyLoader('payment/ebillet')
const ExpressPurchase = svgLazyLoader('payment/express_purchase')
const ExpressDelivery = svgLazyLoader('payment/express_delivery')
const FadingTicket = svgLazyLoader('payment/fading_ticket')
const Flashcode = svgLazyLoader('payment/flashcode')
const FlashcodeError = svgLazyLoader('payment/flashcode_error')
const Mail = svgLazyLoader('payment/mail')
const OptionCheck = svgLazyLoader('payment/option_check')
const OptionDefault = svgLazyLoader('payment/option_default')
const Pass = svgLazyLoader('payment/pass')
const PassFull = svgLazyLoader('payment/pass_full')
const PaymentCard = svgLazyLoader('payment/payment_card')
const PaymentSecured = svgLazyLoader('payment/payment_secured')
const PrintHome = svgLazyLoader('payment/print_home')
const PrintStation = svgLazyLoader('payment/print_station')
const Receipt = svgLazyLoader('payment/receipt')
const Scan = svgLazyLoader('payment/scan')
const Tickets = svgLazyLoader('payment/tickets')
const TicketsCircle = svgLazyLoader('payment/tickets_circle')

const ALL_PAYMENT_ICONS = [
  'bank_card',
  'calendar',
  'calendar_add',
  'calendar_check',
  'card_discount',
  'cart',
  'cart_cryptogram',
  'collect_at_station',
  'ebillet',
  'express-purchase',
  'express_delivery',
  'fading_ticket',
  'flashcode',
  'flashcode-error',
  'mail',
  'option_check',
  'option_default',
  'pass',
  'pass_full',
  'payment_card',
  'payment_secured',
  'print_home',
  'print_station',
  'receipt',
  'scan',
  'tickets',
  'tickets_circle',
] as const
export type PaymentIcon = (typeof ALL_PAYMENT_ICONS)[number]

const isPaymentIcon = (paymentKey: string): paymentKey is PaymentIcon =>
  ALL_PAYMENT_ICONS.includes(paymentKey as PaymentIcon)

const ICONS: LazyIconsGroup<PaymentIcon> = {
  bank_card: BankCard,
  calendar: Calendar,
  calendar_add: CalendarAdd,
  calendar_check: CalendarCheck,
  card_discount: CardDiscount,
  cart: Cart,
  cart_cryptogram: CartCryptogram,
  collect_at_station: CollectAtStation,
  ebillet: Ebillet,
  'express-purchase': ExpressPurchase,
  express_delivery: ExpressDelivery,
  fading_ticket: FadingTicket,
  flashcode: Flashcode,
  'flashcode-error': FlashcodeError,
  mail: Mail,
  option_check: OptionCheck,
  option_default: OptionDefault,
  pass: Pass,
  pass_full: PassFull,
  payment_card: PaymentCard,
  payment_secured: PaymentSecured,
  print_home: PrintHome,
  print_station: PrintStation,
  receipt: Receipt,
  scan: Scan,
  tickets: Tickets,
  tickets_circle: TicketsCircle,
}

export const getPaymentIcon = (paymentKey: string | undefined): LazyIcon | undefined =>
  paymentKey && isPaymentIcon(paymentKey) ? ICONS[paymentKey] : undefined

export const getPaymentIcons = (): LazyIconsGroup<PaymentIcon> => ICONS
