import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const ShuttleModeIcon = svgLazyLoader('transports/bus')
const BusModeIcon = svgLazyLoader('transports/bus_default')
const CarModeIcon = svgLazyLoader('transports/car')
const CableCarModeIcon = svgLazyLoader('transports/cable_car')
const FunicularIcon = svgLazyLoader('transports/funicular')
const MetroModeIcon = svgLazyLoader('transports/metro_empty')
const RerModeIcon = svgLazyLoader('transports/rer_default')
const TrainModeIcon = svgLazyLoader('transports/train')
const TramwayModeIcon = svgLazyLoader('transports/tramway_default')
const RapidTransitModeIcon = svgLazyLoader('transports/transilien_default')
const BikeModeIcon = svgLazyLoader('typology/bike_default')
const WaitingModeIcon = svgLazyLoader('walk/waiting')
const FootModeIcon = svgLazyLoader('walk/walking')
const PlaneModeIcon = svgLazyLoader('transports/plane')

export const ALL_MODE_ICONS = [
  'INTERCITY',
  'METRO',
  'SHUTTLE',
  'RAPID_TRANSIT',
  'BIKE',
  'TGV',
  'HST',
  'TRAIN',
  'OTHER_TRAIN',
  'RER',
  'BUS',
  'COACH',
  'TER',
  'TRAMWAY',
  'FOOT',
  'WAITING',
  'CAR',
  'CAR_AGREEMENT',
  'FUNICULAR',
  'CABLE_CAR',
  'PLANE',
] as const

type ModeIcon = (typeof ALL_MODE_ICONS)[number]

const isModeIcon = (modeKey: string): modeKey is ModeIcon => ALL_MODE_ICONS.includes(modeKey as ModeIcon)

const ICONS: LazyIconsGroup<ModeIcon> = {
  INTERCITY: TrainModeIcon,
  METRO: MetroModeIcon,
  SHUTTLE: ShuttleModeIcon,
  RAPID_TRANSIT: RapidTransitModeIcon,
  BIKE: BikeModeIcon,
  TGV: TrainModeIcon,
  HST: TrainModeIcon,
  TRAIN: TrainModeIcon,
  OTHER_TRAIN: TrainModeIcon,
  RER: RerModeIcon,
  BUS: BusModeIcon,
  COACH: BusModeIcon,
  TER: TrainModeIcon,
  TRAMWAY: TramwayModeIcon,
  FOOT: FootModeIcon,
  WAITING: WaitingModeIcon,
  CAR: CarModeIcon,
  CAR_AGREEMENT: ShuttleModeIcon,
  FUNICULAR: FunicularIcon,
  CABLE_CAR: CableCarModeIcon,
  PLANE: PlaneModeIcon,
}

export const getModeIcon = (
  modeKey: string | undefined,
  transportLabel: string | undefined = undefined
): LazyIcon | undefined => {
  if (modeKey && isModeIcon(modeKey)) {
    if (transportLabel === 'AUTOCAR') {
      return ICONS.SHUTTLE
    }

    return ICONS[modeKey]
  }

  return undefined
}

export const getAllModeIcon = (): LazyIconsGroup<ModeIcon> => ICONS
