import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'

import ButtonCart from '@Components/designSystem/navigation/buttonCart/ButtonCart'
import { DEFAULT_LOCALE } from '@Constants/locale'
import useCartWithoutStore from '@Hooks/cart/useCartWithoutStore'
import { useIsMediumUp } from '@Hooks/useResponsive'
import { NEXT_ROUTES } from '@Routes/routes'
import { PublicRuntimeConfiguration } from '@Utils/config'

declare const PUBLIC_RUNTIME_CONFIG: PublicRuntimeConfiguration

const CartButtonWidget: FC = () => {
  const theme = useTheme()
  const isMediumUp = useIsMediumUp(theme)
  const [isCartLoading, setIsCartLoading] = useState(false)
  const [cartItemsCount, setCartItemsCount] = useState<number>()
  const { isGetCartError, getCart } = useCartWithoutStore()
  const { locale } = useIntl()

  // TODO IVTS-62478 Can't use makeNextPath util here because runtimeConfig is not available
  const buttonCartHref =
    locale && locale !== DEFAULT_LOCALE
      ? `${PUBLIC_RUNTIME_CONFIG.frontEndBasePath}/${locale}${NEXT_ROUTES.cart}`
      : `${PUBLIC_RUNTIME_CONFIG.frontEndBasePath}${NEXT_ROUTES.cart}`

  useEffect(() => {
    const getRemoteCart = async (...getCartParams: Parameters<typeof getCart>) => {
      setIsCartLoading(true)
      setCartItemsCount((await getCart(...getCartParams))?.items.length || 0)
      setIsCartLoading(false)
    }

    if (!isCartLoading && !isGetCartError && cartItemsCount === undefined) {
      getRemoteCart(true, false)
    }
  }, [cartItemsCount, getCart, isCartLoading, isGetCartError])

  if (!cartItemsCount) return null

  return <ButtonCart itemCount={cartItemsCount} kind={isMediumUp ? 'normal' : 'compact'} href={buttonCartHref} />
}

export default CartButtonWidget
