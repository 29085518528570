import { Pathname } from '@Types/navigation/navigation'

type NextRouteName =
  | 'account'
  | 'authenticate'
  | 'accountActivation'
  | 'accountChangePassword'
  | 'accountCreation'
  | 'accountCompanions'
  | 'accountConsentManagement'
  | 'accountLoyaltyAndDiscountCards'
  | 'accountPaymentMethods'
  | 'accountPersonalInfo'
  | 'accountProfessionalSpace'
  | 'accountMyYear'
  | 'accountSalesOpeningAlertNew'
  | 'accountAlerts'
  | 'accountAlertsLowPriceAlert'
  | 'accountAlertsMaxAlert'
  | 'accountAlertsMaxAutoBooking'
  | 'accountAlertsSalesOpeningAlert'
  | 'accountAlertsFullTrainAlert'
  | 'accountBetaMode'
  | 'additionalServicesCancellationConfirmation'
  | 'additionalServicesCancellationQuote'
  | 'additionalServicesCart'
  | 'additionalServicesPayment'
  | 'additionalServicesPaymentConfirmation'
  | 'additionalServicesPaymentValidation'
  | 'cancellationJourneysAndPassengers'
  | 'cancellationQuotationPass'
  | 'cancellationQuotationCard'
  | 'cancellationQuotation'
  | 'catalogue'
  | 'catalogueDescription'
  | 'cataloguePricingSncf'
  | 'cataloguePricingSncfStep2'
  | 'cataloguePricingSncfStep3'
  | 'cataloguePricingTerWishes'
  | 'cataloguePricingTerOffers'
  | 'doorToDoorItineraries'
  | 'exchangeItineraryOutward'
  | 'exchangeItineraryInward'
  | 'exchangeBooking'
  | 'exchangeBookingPayment'
  | 'exchangePayment'
  | 'exchangeJourneysAndTravelers'
  | 'exchangeStep1'
  | 'exchangeStep2'
  | 'exchangeStep3'
  | 'exchangeValidation'
  | 'journeyTimelineDetails'
  | 'logout'
  | 'search'
  | 'searchOD'
  | 'rideHailingSearch'
  | 'rideHailingEstimates'
  | 'rideHailingContact'
  | 'rideHailingOrder'
  | 'rideHailingOrders'
  | 'rideHailingPayment'
  | 'rideHailingPaymentValidation'
  | 'rideHailingHelp'
  | 'rideHailingGuestZonesSubZones'
  | 'rideHailingGuestZonesAccessPoints'
  | 'interstitiel'
  | 'payment'
  | 'redirect'
  | 'consultationTripSearch'
  | 'consultationTripSearchResults'
  | 'consultationTripSearchFindReference'
  | 'consultationTripDetailTicketing'
  | 'consultationTripDetail'
  | 'tripsConsultation'
  | 'consultationPreReservations'
  | 'ouigoOptions'
  | 'shopItineraryInwardResults'
  | 'shopItineraryOutwardResults'
  | 'contactInformation'
  | 'confirmationExchange'
  | 'confirmation'
  | 'confirmationCancellationPreReservation'
  | 'confirmationCancellationFinalization'
  | 'confirmationCardCancellationFinalization'
  | 'paymentValidation'
  | 'cart'
  | 'cartServices'
  | 'trafficInfo'
  | 'stationTimetableSearch'
  | 'stationTimetableResults'
  | 'station'
  | 'proofOfPurchase'

export const NEXT_ROUTES = {
  account: '/account',
  accountActivation: '/account/activation',
  accountChangePassword: '/account/change-password',
  accountConsentManagement: '/account/consentManagement',
  accountCompanions: '/account/companions',
  accountCreation: '/account/creation',
  accountLoyaltyAndDiscountCards: '/account/loyaltyAndDiscountCards',
  accountPaymentMethods: '/account/paymentMethods',
  accountPersonalInfo: '/account/personal-info',
  accountProfessionalSpace: '/account/professional-space',
  accountMyYear: '/account/my-year-summary',
  accountSalesOpeningAlertNew: '/account/sales-opening/create/od',
  accountAlerts: '/account/alerts',
  accountAlertsLowPriceAlert: '/account/alerts/low-price-alert',
  accountAlertsMaxAlert: '/account/alerts/max-alert',
  accountAlertsMaxAutoBooking: '/account/alerts/max-auto-booking',
  accountAlertsSalesOpeningAlert: '/account/alerts/sales-opening-alert',
  accountAlertsFullTrainAlert: '/account/alerts/full-train-alert',
  accountBetaMode: '/account/beta-mode',
  additionalServicesCancellationConfirmation: '/additional-services/cancellation/confirmation',
  additionalServicesCancellationQuote: '/additional-services/cancellation/quote',
  additionalServicesCart: '/additional-services/cart/',
  additionalServicesPayment: '/additional-services/payment',
  additionalServicesPaymentConfirmation: '/additional-services/payment/confirmation',
  additionalServicesPaymentValidation: '/additional-services/payment/validation',
  authenticate: '/authenticate',
  cancellationJourneysAndPassengers: '/cancellation/journeys-and-travelers',
  cancellationQuotationPass: '/cancellation/quotationPass',
  cancellationQuotationCard: '/cancellation/quotationCard',
  cancellationQuotation: '/cancellation/quotation',
  cart: '/cart',
  cartServices: '/cart/services',
  catalogue: '/catalogue',
  catalogueDescription: '/catalogue/description',
  cataloguePricingSncf: '/catalogue/pricing/sncf',
  cataloguePricingSncfStep2: '/catalogue/pricing/sncf-step2',
  cataloguePricingSncfStep3: '/catalogue/pricing/sncf-step3',
  cataloguePricingTerWishes: '/catalogue/pricing/ter/wishes',
  cataloguePricingTerOffers: '/catalogue/pricing/ter/offers',
  confirmation: '/confirmation',
  confirmationCardCancellationFinalization: '/confirmation/cancellation/finalization/card',
  confirmationCancellationFinalization: '/confirmation/cancellation/finalization',
  confirmationCancellationPreReservation: '/confirmation/cancellation/preReservation',
  confirmationExchange: '/confirmation/exchange',
  consultationTripDetail: '/trips/detail',
  consultationTripDetailTicketing: '/trips/detail/ticketing',
  consultationTripSearch: '/trips/search',
  consultationTripSearchResults: '/trips/search/results',
  consultationTripSearchFindReference: '/trips/search/find-reference',
  consultationPreReservations: '/trips/preReservation',
  contactInformation: '/contact/information',
  doorToDoorItineraries: '/home/doorToDoor/itineraries',
  exchangeBooking: '/exchange/booking',
  exchangeBookingPayment: '/exchange/bookingPayment',
  exchangeItineraryInward: '/exchange/search/inward',
  exchangeItineraryOutward: '/exchange/search/outward',
  exchangePayment: '/exchange/payment',
  exchangeJourneysAndTravelers: '/exchange/journeys-and-travelers',
  exchangeStep1: '/exchange/step1',
  exchangeStep2: '/exchange/step2',
  exchangeStep3: '/exchange/step3',
  exchangeValidation: '/exchange/validation',
  interstitiel: '/interstitiel',
  journeyTimelineDetails: '/journeyTimelineDetails',
  logout: '/logout',
  ouigoOptions: '/consultation/ouigoOptions',
  payment: '/payment',
  paymentValidation: '/payment/validation',
  proofOfPurchase: '/account/proof-of-purchase',
  redirect: '/redirect',
  search: '/home/search',
  searchOD: '/home/search/od',
  rideHailingSearch: '/ride-hailing/search',
  rideHailingEstimates: '/ride-hailing/estimates',
  rideHailingContact: '/ride-hailing/contact',
  rideHailingOrder: '/ride-hailing/order',
  rideHailingOrders: '/ride-hailing/orders',
  rideHailingPayment: '/ride-hailing/payment',
  rideHailingPaymentValidation: '/ride-hailing/payment/validation',
  rideHailingHelp: '/ride-hailing/help',
  rideHailingGuestZonesSubZones: '/ride-hailing/guest-zones/sub-zones',
  rideHailingGuestZonesAccessPoints: '/ride-hailing/guest-zones/access-points',
  shopItineraryOutwardResults: '/home/shop/results/outward',
  shopItineraryInwardResults: '/home/shop/results/inward',
  tripsConsultation: '/trips',
  trafficInfo: '/trafficInfo',
  stationTimetableSearch: '/station-timetable',
  stationTimetableResults: '/station-timetable/results',
  station: '/station',
} satisfies Record<NextRouteName, Pathname>

export const PAYMENT_ROUTES = [NEXT_ROUTES.payment, NEXT_ROUTES.exchangePayment, NEXT_ROUTES.rideHailingPayment]

export const HOME_PATH = '/'
