// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const AisleSide = svgLazyLoader('placement/aisle_side')
const AloneWoman = svgLazyLoader('placement/alone_woman')
const Club6 = svgLazyLoader('placement/club_6')
const Club6Center = svgLazyLoader('placement/club_6_center')
const ClubDuo = svgLazyLoader('placement/club_duo')
const ClubDuoTablet = svgLazyLoader('placement/club_duo_tablet')
const Comfort = svgLazyLoader('placement/comfort')
const Compartment = svgLazyLoader('placement/compartment')
const Compartment8 = svgLazyLoader('placement/compartment_8')
const EasyAccess = svgLazyLoader('placement/easy_access')
const HighArea = svgLazyLoader('placement/high_area')
const Inclinable = svgLazyLoader('placement/inclinable')
const Kiosque = svgLazyLoader('placement/kiosque')
const LowArea = svgLazyLoader('placement/low_area')
const NoArea = svgLazyLoader('placement/no_area')
const NoPlacement = svgLazyLoader('placement/no_placement')
const OldPerson = svgLazyLoader('placement/old_person')
const Panoramique = svgLazyLoader('placement/panoramique')
const Pregnancy = svgLazyLoader('placement/pregnancy')
const PrioritySeat = svgLazyLoader('placement/priority_seat')
const QuietPlaceCatch = svgLazyLoader('services/quiet_place_catch')
const Room = svgLazyLoader('placement/room')
const SeatmapOuigo = svgLazyLoader('placement/seatmap_ouigo')
const SideBySide = svgLazyLoader('placement/side_by_side')
const Solo = svgLazyLoader('placement/solo')
const Square = svgLazyLoader('placement/square')
const VisuallyImpaired = svgLazyLoader('placement/visually_impaired')
const WalkDirection = svgLazyLoader('placement/walk_direction')
const WindowSide = svgLazyLoader('placement/window_side')

const ALL_PLACEMENT_ICONS = [
  'aisle_side',
  'alone_woman',
  'club_6',
  'club_6_center',
  'club_duo',
  'club_duo_tablet',
  'comfort',
  'compartment',
  'compartment_8',
  'easy_access',
  'high_area',
  'inclinable',
  'kiosque',
  'low_area',
  'no_area',
  'no_placement',
  'old_person',
  'panoramique',
  'pregnancy',
  'priority_seat',
  'quiet-place-catch',
  'room',
  'seatmap_ouigo',
  'side_by_side',
  'solo',
  'square',
  'visually_impaired',
  'walk_direction',
  'window_side',
] as const
export type PlacementIcon = (typeof ALL_PLACEMENT_ICONS)[number]

const isPlacementIcon = (placementKey: string): placementKey is PlacementIcon =>
  ALL_PLACEMENT_ICONS.includes(placementKey as PlacementIcon)

const ICONS: LazyIconsGroup<PlacementIcon> = {
  aisle_side: AisleSide,
  alone_woman: AloneWoman,
  club_6: Club6,
  club_6_center: Club6Center,
  club_duo: ClubDuo,
  club_duo_tablet: ClubDuoTablet,
  comfort: Comfort,
  compartment: Compartment,
  compartment_8: Compartment8,
  easy_access: EasyAccess,
  high_area: HighArea,
  inclinable: Inclinable,
  kiosque: Kiosque,
  low_area: LowArea,
  no_area: NoArea,
  no_placement: NoPlacement,
  old_person: OldPerson,
  panoramique: Panoramique,
  pregnancy: Pregnancy,
  priority_seat: PrioritySeat,
  'quiet-place-catch': QuietPlaceCatch,
  room: Room,
  seatmap_ouigo: SeatmapOuigo,
  side_by_side: SideBySide,
  solo: Solo,
  square: Square,
  visually_impaired: VisuallyImpaired,
  walk_direction: WalkDirection,
  window_side: WindowSide,
}

export const getPlacementIcon = (placementKey: string | undefined): LazyIcon | undefined =>
  placementKey && isPlacementIcon(placementKey) ? ICONS[placementKey] : undefined

export const getPlacementIcons = (): LazyIconsGroup<PlacementIcon> => ICONS
