import { ElementType, FC } from 'react'

import LinkBase from '../_internal/LinkBase'
import { LinkBaseProps } from '../_internal/LinkBase.types'

import * as styles from './styles'

type TertiaryButtonProps = Omit<
  LinkBaseProps<'button'>,
  | 'underline'
  | 'locale'
  | 'isBasePath'
  | 'isInline'
  | 'hasNoSpacing'
  | 'hasDefaultFocusVisible'
  | 'isObfuscated'
  | 'isNextRoute'
  | 'isExternal'
>

const TertiaryButton: FC<TertiaryButtonProps> = ({ children, ...rest }) => (
  <LinkBase {...rest} css={styles.button} component={'button' as ElementType} underline="none" type="button">
    {children}
  </LinkBase>
)

export default TertiaryButton
