import { FC, useCallback, useEffect, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { AutocompleteFullTextSuggestion } from 'invictus-sdk-typescript'

import FullTextSuggestionsSection from '@Components/designSystem/navigation/search/atoms/FullTextSuggestionsSection'
import Autocomplete from '@Components/designSystem/navigation/search/autocomplete/Autocomplete'
import { AutocompleteSection } from '@Components/designSystem/navigation/search/autocomplete/Autocomplete.types'
import OlympicGamesTips from '@Components/presentational/search/olympicGames/OlympicGamesTips'
import TutoSearch from '@Components/presentational/search/tutoSearch/TutoSearch'
import { FULL_TEXT_SUGGESTIONS_AREA, useSearchAutocomplete } from '@Hooks/autocomplete/useSearchAutocomplete'
import { useSearchAutocompleteSuggestions } from '@Hooks/autocomplete/useSearchAutocompleteSuggestions'
import { AutocompleteResultModel, getIsFullTextSuggestion } from '@Utils/autocomplete'

import AutocompleteNoResults from './AutocompleteNoResults'
import * as styles from './styles'

export type AutocompleteWidgetProps = {
  input: HTMLInputElement
  eventName: string
  onError?: (error: unknown) => void
  shouldReturnSuggestions?: boolean
}

const AutocompleteWidget: FC<AutocompleteWidgetProps> = ({
  input,
  eventName,
  onError,
  shouldReturnSuggestions = false,
}) => {
  const theme = useTheme()
  const inputRef = useRef(input)

  const dispatchSelectCustomEvent = useCallback(
    (item: AutocompleteResultModel) => {
      // Timeout nécessaire pour la prise en compte des fullTextSuggestions (mais on ne se souvient plus pourquoi :x)
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent(eventName, { detail: item }))
      })
    },
    [eventName]
  )

  const [
    suggestions,
    {
      autocompleteTutorial,
      olympicGamesTips,
      hasToDisplayTuto,
      hasToDisplayOlympicGamesTips,
      closeTuto,
      closeOlympicGamesTips,
    },
  ] = useSearchAutocompleteSuggestions(['destination', 'itinerary'], {
    withTuto: true,
  })

  const [
    {
      autoComplete,
      inputProps: {
        'aria-activedescendant': ariaActivedescendant = undefined,
        'aria-autocomplete': ariaAutocomplete = undefined,
        'aria-describedby': ariaDescribedby = undefined,
        'aria-expanded': ariaExpanded = undefined,
        'aria-haspopup': ariaHaspopup = undefined,
        'aria-owns': ariaOwns = undefined,
        role = undefined,
      } = {},
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      value,
    },
    autocompleteProps,
    { setInputValue },
    { displayMode, hasNoResults },
  ] = useSearchAutocomplete(suggestions, {
    autocompleteProps: { onSelectItem: dispatchSelectCustomEvent },
    inputProps: { defaultValue: input.value, inputProps: { 'aria-describedby': 'autocomplete_tuto_search' }, inputRef },
    onError,
    shouldReturnSuggestions,
  })

  const handleChange = useCallback(
    (event: Event) => {
      onChange(event as Event & { currentTarget: HTMLInputElement })
    },
    [onChange]
  )

  useEffect(() => {
    // Manually trigger focus handler if input element is initialized with focus
    if (input === document.activeElement) {
      onFocus()
    }
  }, [input, onFocus])

  const setAttribute = useCallback(
    (attributeName: string, attributeValue: unknown | undefined) => {
      const originalValue = input.hasAttribute(attributeName) ? input.getAttribute(attributeName) : undefined

      const effectivelySetAttribute = (newValue: unknown | undefined) => {
        if (newValue !== undefined) {
          input.setAttribute(attributeName, `${newValue}`)
        } else if (input.hasAttribute(attributeName)) {
          input.removeAttribute(attributeName)
        }
      }

      effectivelySetAttribute(attributeValue)

      return () => {
        effectivelySetAttribute(originalValue)
      }
    },
    [input]
  )

  // Statics attributes
  useEffect(() => {
    const removeAttributes = [
      setAttribute('autocomplete', autoComplete),
      setAttribute('aria-autocomplete', ariaAutocomplete),
      setAttribute('aria-describedby', ariaDescribedby),
      setAttribute('aria-haspopup', `${ariaHaspopup}`),
      setAttribute('role', role),
    ]

    return () => removeAttributes.forEach((removeAttribute) => removeAttribute())
  }, [ariaAutocomplete, ariaDescribedby, ariaHaspopup, autoComplete, input, role, setAttribute])

  // Dynamics attributes
  useEffect(() => {
    setAttribute('aria-expanded', ariaExpanded)
  }, [ariaExpanded, setAttribute])

  useEffect(() => {
    setAttribute('aria-owns', ariaOwns)
  }, [ariaOwns, setAttribute])

  useEffect(() => {
    setAttribute('aria-activedescendant', ariaActivedescendant)
  }, [ariaActivedescendant, setAttribute])

  useEffect(() => {
    input.value = value
  }, [input, value])

  useEffect(() => {
    setInputValue(input.value)
  }, [input.value, setInputValue])

  useEffect(() => {
    input.addEventListener('blur', onBlur)

    return () => {
      input.removeEventListener('blur', onBlur)
    }
  }, [input, onBlur])

  useEffect(() => {
    input.addEventListener('focus', onFocus)

    return () => {
      input.removeEventListener('focus', onFocus)
    }
  }, [input, onFocus])

  useEffect(() => {
    input.addEventListener('input', handleChange)

    return () => {
      input.removeEventListener('input', handleChange)
    }
  }, [handleChange, input])

  useEffect(() => {
    input.addEventListener('keydown', onKeyDown)

    return () => {
      input.removeEventListener('keydown', onKeyDown)
    }
  }, [input, onKeyDown])

  useEffect(() => {
    window.IvtsWidgets.autocomplete.helpers.setInputValue = setInputValue

    if (window.IvtsWidgets.autocomplete.helpers.stackedValue) {
      setInputValue(window.IvtsWidgets.autocomplete.helpers.stackedValue)
      window.IvtsWidgets.autocomplete.helpers.stackedValue = ''
    }
  }, [setInputValue])

  const shouldDisplayOlympicGamesTips =
    !!olympicGamesTips && hasToDisplayOlympicGamesTips && displayMode === 'suggestions'

  const shouldDisplayTutoSearch =
    autocompleteTutorial && hasToDisplayTuto && displayMode === 'suggestions' && !shouldDisplayOlympicGamesTips
  const { sections, itemRef, highlighted, onItemHover } = autocompleteProps

  const fullTextSuggestionsSection = sections.find(({ area }) => area === FULL_TEXT_SUGGESTIONS_AREA) as
    | AutocompleteSection<AutocompleteFullTextSuggestion>
    | undefined
  const othersSections = sections.filter(({ area }) => area !== FULL_TEXT_SUGGESTIONS_AREA)

  const highlightedFullTextSuggestion = highlighted && getIsFullTextSuggestion(highlighted) ? highlighted : undefined
  const hasContentToDisplay =
    shouldDisplayTutoSearch || hasNoResults || !!fullTextSuggestionsSection || shouldDisplayOlympicGamesTips

  return (
    <Autocomplete {...autocompleteProps} sections={othersSections}>
      {hasContentToDisplay && (
        <>
          {shouldDisplayTutoSearch && (
            <TutoSearch
              aria-hidden
              data-cs-override-id="search_push-tuto"
              id="autocomplete_tuto_search"
              css={styles.tutoSearch(theme)}
              autocompleteTutorial={autocompleteTutorial}
              onClose={closeTuto}
            />
          )}
          {shouldDisplayOlympicGamesTips && (
            <OlympicGamesTips
              data-cs-override-id="search_push-JO"
              onClose={closeOlympicGamesTips}
              title={olympicGamesTips.title}
              subtitle={olympicGamesTips.subtitle}
            />
          )}
          {hasNoResults && <AutocompleteNoResults inputValue={value} />}
          {!!fullTextSuggestionsSection?.items.length && (
            <FullTextSuggestionsSection
              title={fullTextSuggestionsSection.title}
              items={fullTextSuggestionsSection.items}
              onSelectItem={dispatchSelectCustomEvent}
              onItemOver={onItemHover}
              highlighted={highlightedFullTextSuggestion}
              itemRef={itemRef}
            />
          )}
        </>
      )}
    </Autocomplete>
  )
}

export default AutocompleteWidget
