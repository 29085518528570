import { ReactElement } from 'react'
import { useTheme } from '@mui/material/styles'

import { LazyIcon, SVGIcon } from '@Components/designSystem/icons/icons.types'
import { getColor } from '@Components/designSystem/illustrations/Illustration.utils'
import { AuthorizedColor, SnapIllustrationProps } from '@Components/designSystem/illustrations/types'
import { useIsMediumUp } from '@Hooks/useResponsive'

import * as styles from './styles'

type SnapBaseProps<ColorType extends AuthorizedColor> = SnapIllustrationProps<ColorType> & {
  desktopIllustration: LazyIcon | SVGIcon
  illustration: LazyIcon | SVGIcon
  defaultColorName: ColorType
  colorName?: ColorType
}

const SnapBase = <ColorType extends AuthorizedColor>({
  desktopIllustration: DesktopIllustration,
  illustration: Illustration,
  defaultColorName,
  colorName,
  isSmall = false,
  ...rest
}: SnapBaseProps<ColorType>): ReactElement | null => {
  const theme = useTheme()
  const isDesktop = useIsMediumUp(theme)

  return (
    <div {...rest} css={styles.illustration(theme, getColor(theme, colorName || defaultColorName), isSmall)}>
      {isDesktop ? <DesktopIllustration /> : <Illustration />}
    </div>
  )
}

export default SnapBase
