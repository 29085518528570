import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'

import { SPACING_0, SPACING_1, SPACING_2, SPACING_6 } from '@Styles/constants/spacing'

import { HighlightCardVariant } from '../types'

export const header = (theme: Theme, isOnSuggestion: boolean, isFullWidth: boolean): SerializedStyles =>
  css({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: `${SPACING_2} ${SPACING_2} ${SPACING_1}`,

    [theme.breakpoints.up('sm')]: {
      ...(isOnSuggestion && {
        marginBottom: SPACING_2,
        marginRight: `calc((${SPACING_0} * 2) + ${SPACING_6})`,
      }),
      ...(!isFullWidth && {
        alignItems: 'center',
        flexDirection: 'row',
      }),
    },
  })

export const tag = css({
  flexShrink: 0,
})

export const subtitle = (theme: Theme, isFullWidth: boolean): SerializedStyles =>
  css({
    marginTop: SPACING_2,
    whiteSpace: 'pre-wrap', // NOTE: discuss this with CXs
    ...(!isFullWidth && {
      [theme.breakpoints.up('sm')]: {
        marginLeft: SPACING_1,
        marginTop: 0,
      },
    }),
  })

export const backgroundColors = (theme: Theme, variant: HighlightCardVariant): SerializedStyles =>
  css({
    backgroundColor: {
      proactivity: theme.customPalette.alternative.service,
      promo: theme.customPalette.alternative.communication,
      tips: theme.customPalette.alternative.info,
      travel: theme.customPalette.alternative.extraIcon2,
    }[variant],
  })

export const innerCard = css({
  margin: `0 ${SPACING_0} ${SPACING_0}`,
})
