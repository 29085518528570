import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { SPACING_1, SPACING_2 } from '@Styles/constants/spacing'

export const illustration = (theme: Theme, color: string): SerializedStyles =>
  css({
    color,
    height: rem(140),
    margin: SPACING_1,
    width: rem(296),
    [theme.breakpoints.up('md')]: {
      height: rem(180),
      margin: `${SPACING_1} ${SPACING_2} `,
      width: rem(596),
    },
  })
