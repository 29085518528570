import { css, SerializedStyles } from '@emotion/react'
import { cardHeaderClasses } from '@mui/material/CardHeader'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { FULL_RADIUS } from '@Styles/constants/radius'
import { spacing, SPACING_0, SPACING_1, SPACING_2 } from '@Styles/constants/spacing'

export const closeIcon = (theme: Theme): SerializedStyles =>
  css({
    ...size(rem(24)),
    display: 'block',
    fill: theme.customPalette.alternative.onAlternativeColor2,
  })

export const illuTutoIcon = (theme: Theme): SerializedStyles =>
  css({
    color: theme.customPalette.alternative.onAlternativeColor2,
    display: 'block',
  })

export const cardHeader = (theme: Theme): SerializedStyles =>
  css({
    [`& .${cardHeaderClasses.action}`]: {
      margin: `-${SPACING_1} -${SPACING_1} 0 0`,
      [theme.breakpoints.up('md')]: {
        margin: `-${spacing(1.5)} -${spacing(1.5)} 0 0`,
      },
    },
    [`& .${cardHeaderClasses.avatar}`]: {
      marginRight: SPACING_1,
    },
    [`& .${cardHeaderClasses.content}`]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [`& .${cardHeaderClasses.subheader}`]: {
      marginTop: SPACING_1,
    },
    alignItems: 'stretch',
    color: theme.customPalette.alternative.onAlternativeColor2,
    padding: `${SPACING_1} ${SPACING_1} ${SPACING_1} 0`,
    [theme.breakpoints.up('md')]: {
      padding: `${SPACING_2} ${SPACING_2} ${SPACING_2} 0`,
    },
  })

export const cardContent = css({
  '&:last-child': {
    paddingBottom: SPACING_1,
  },
  padding: `0 ${SPACING_2}`,
})

export const tagsTuto = css({
  '& > *': {
    margin: `${SPACING_1} 0 0 ${SPACING_1}`,
  },
  display: 'flex',
  flexWrap: 'wrap',
  listStyleType: 'none',
  margin: `-${SPACING_1} 0 0 -${SPACING_1}`,
  padding: 0,
  whiteSpace: 'nowrap',
})

export const tagTuto = (theme: Theme): SerializedStyles =>
  css({
    border: `1px solid ${theme.customPalette.alternative.onAlternativeColor2}`,
    borderRadius: FULL_RADIUS,
    color: theme.customPalette.alternative.onAlternativeColor2,
    display: 'inline-block',
    padding: `${SPACING_0} ${SPACING_2}`,
  })
