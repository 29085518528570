import { Redirect } from 'next'

import { allLocales, DEFAULT_LOCALE } from '@Constants/locale'
import Locale from '@Types/locale'
import { Pathname } from '@Types/navigation/navigation'
import { getPublicRuntimeConfig } from '@Utils/config'

const { frontendPublicUrl = '', frontEndBasePath = '' } = getPublicRuntimeConfig()

export const isLocalhostUrl = (url: string): boolean => {
  try {
    const { hostname } = new URL(url)

    return hostname === 'localhost' || hostname === '127.0.0.1'
  } catch (e) {
    return false
  }
}

export const isExternalUrl = (url: string): boolean => /^https?/.test(url)

// TODO: A gérer, internationalisation de l'url
const DRUPAL_ERROR_URL = '/erreur-interne'

export const getPathnameWithoutQueryParams = (asPath: string): string => asPath.split('?')[0]

export const hasBasePathPrefix = (url: string): boolean => !!frontEndBasePath && url.startsWith(frontEndBasePath)

/**
 * NextJS automatically removes the locale prefix in the current URL when the locale is same as the default locale "fr-fr"
 * You probably want to skip `basePath` if used in redirect with next router as it will be added automatically in URL
 * @param pathname
 * @param locale
 * @param skipBasePath
 */
export const getRelativeUrl = (pathname: Pathname, locale: Locale = DEFAULT_LOCALE, skipBasePath = false): string =>
  `${skipBasePath ? '' : frontEndBasePath}${locale === DEFAULT_LOCALE ? '' : `/${locale}`}${pathname}`

export const getAbsoluteUrl = (pathname: Pathname, locale: Locale = DEFAULT_LOCALE, skipBasePath = false): string =>
  `${window.location.origin}${hasBasePathPrefix(pathname) ? pathname : getRelativeUrl(pathname, locale, skipBasePath)}`

export const getFrontendPublicUrl = (
  pathname: Pathname,
  locale: Locale = DEFAULT_LOCALE,
  skipBasePath = false
): string =>
  // TODO: To fix on the CI
  // new URL(getRelativeUrl(pathname, locale, skipBasePath), frontendPublicUrl).toString()
  `${frontendPublicUrl}${getRelativeUrl(pathname, locale, skipBasePath)}`

export const addFrontEndBasePath = (pathname: Pathname): string => `${frontEndBasePath}${pathname}`

export const getPathWithoutBasePathAndLocale = (pathWithBasePathAndLocale: string): string => {
  const regexpStr = `^(?:${frontEndBasePath})?(?:/(?:${allLocales.join('|')}))?(.*)`

  return new RegExp(regexpStr).exec(pathWithBasePathAndLocale)?.[1] as string
}

export const makeNextPath = (
  pathname: string,
  locale?: string | undefined,
  basePath: string = frontEndBasePath
): string => {
  const path = getPathWithoutBasePathAndLocale(pathname)

  return `${pathname !== '/' ? basePath : ''}${
    locale && locale !== DEFAULT_LOCALE ? `/${locale}` : ''
  }${path}` as Pathname
}
export const removeBasePathPrefix = (url: string): string =>
  hasBasePathPrefix(url) ? url.slice(frontEndBasePath.length) : url

export const redirectToError = (): void => window.location.replace(DRUPAL_ERROR_URL)

const DRUPAL_NOT_FOUND_URL = '/document-non-trouve'

export const getServerSideProps404Redirect = (locale: string | undefined): Redirect => ({
  destination: `${locale && locale !== DEFAULT_LOCALE ? `/${locale}` : ''}${DRUPAL_NOT_FOUND_URL}`,
  permanent: false,
  basePath: false,
})
