import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { LAVENDER } from '@Styles/constants/colors'
import { SPACING_1 } from '@Styles/constants/spacing'

export const recentSearchResultContainer = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
})

export const recentSearchResultDestinationContainer = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  minWidth: 0,
})

export const recentSearchLabel = css({
  overflow: 'hidden',
  paddingRight: SPACING_1,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const recentSearchInwardIcon = css({
  flexShrink: 0,
  ...size(rem(16)),
  marginRight: SPACING_1,
})

export const recentSearchDescriptionContainer = css({
  backgroundColor: LAVENDER,
  borderRadius: SPACING_1,
  width: 'fit-content',
})

export const recentSearchDescription = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.primary,
    paddingLeft: SPACING_1,
    paddingRight: SPACING_1,
  })
