import { CSSProperties } from 'react'
import { css, CSSObject, SerializedStyles } from '@emotion/react'
import { rem } from 'polished'

import { highContrast } from '@Styles/a11y'
import { Z_INDEX_MEDIUM } from '@Styles/constants/layout'
import { STANDARD_RADIUS } from '@Styles/constants/radius'
import { getInnerBorderRadius } from '@Utils/border'

export const FOCUS_OFFSET = rem(2)
// le 'px' est voulu, on ne veut pas que le liseret scale avec le zoom. voir IVTS-3503
export const BORDER_SIZE = '2px'

const getPositionBorderFocus = (offSet: string): CSSProperties => {
  const [offSetTop = offSet, offSetRight = offSetTop, offSetBottom = offSetTop, offSetLeft = offSetTop] =
    offSet.split(' ')

  return {
    // TODO à la sortie de safari 15 remplacer bottom,left,right,top par inset
    bottom: offSetBottom,
    left: offSetLeft,
    right: offSetRight,
    top: offSetTop,
  }
}

/**
 * Fonction utilisée dans les overrides material car dans ce contexte on veut les règles CSS "pures"
 *
 * @param borderColor
 * @param borderRadius
 * @param offSet
 */
export const getRawFocusVisibleStyle = (
  borderColor: CSSProperties['color'],
  borderRadius = STANDARD_RADIUS,
  offSet: string = FOCUS_OFFSET
): CSSObject => {
  const [
    borderTopLeftRadius = STANDARD_RADIUS,
    borderTopRightRadius = borderTopLeftRadius,
    borderBottomRightRadius = borderTopLeftRadius,
    borderBottomLeftRadius = borderTopLeftRadius,
  ] = borderRadius.split(' ')

  const focusOffset = (offSet.split(' ').length === 1 && offSet) || FOCUS_OFFSET

  return {
    border: `${BORDER_SIZE} solid ${borderColor}`,
    borderRadius: `
      ${getInnerBorderRadius(borderTopLeftRadius, focusOffset)}
      ${getInnerBorderRadius(borderTopRightRadius, focusOffset)}
      ${getInnerBorderRadius(borderBottomRightRadius, focusOffset)}
      ${getInnerBorderRadius(borderBottomLeftRadius, focusOffset)}`,
    content: `''`,
    display: 'block',
    position: 'absolute',
    ...getPositionBorderFocus(offSet),
  }
}

/**
 * Fonction utilisée dans les composants où on passe par `@emotion`
 *
 * @param borderColor
 * @param borderRadius
 * @param offSet
 */
export const getFocusVisibleStyle = (
  borderColor: CSSProperties['color'],
  borderRadius: string = STANDARD_RADIUS,
  offSet: string = FOCUS_OFFSET
): CSSObject => getRawFocusVisibleStyle(borderColor, borderRadius, offSet)

export const applyFocusVisible = (...args: Parameters<typeof getFocusVisibleStyle>): SerializedStyles =>
  css({
    '&::before': getFocusVisibleStyle(...args),
    ...highContrast({
      border: 'solid 1px transparent',
    }),
    position: 'relative',
  })

export const getFocusVisibleIconButtonStyle = (borderColor: CSSProperties['color'], offSet: string): SerializedStyles =>
  css({
    border: `${BORDER_SIZE} solid ${borderColor}`,
    borderRadius: '50%',
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: Z_INDEX_MEDIUM,
    ...getPositionBorderFocus(offSet),
  })
