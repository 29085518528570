import { FC, useContext } from 'react'
import { createPortal } from 'react-dom'
import { ThemeProvider } from '@mui/material/styles'

import { SnackbarContext } from '@Contexts/snackbar/SnackbarContext'
import Snackbar from '@DS/components/components/snackbar/Snackbar'
import { darkTheme } from '@Material/theme'

const GlobalSnackbar: FC = () => {
  const value = useContext(SnackbarContext)

  return createPortal(
    <ThemeProvider theme={darkTheme}>
      <Snackbar message={value?.message} isOpen={value?.isOpen} onClose={value?.close} />
    </ThemeProvider>,
    document.body
  )
}

export default GlobalSnackbar
