import { useIntl } from 'react-intl'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SdkError } from 'invictus-sdk-typescript'

import { useSearchSuggestionsSdk } from '@Hooks/sdk/useSearchSuggestionsSdk'
import useSnackbar from '@Hooks/useSnackbar'
import { QUERY_KEY } from '@Types/queryKey'

type SearchAutocompleteDeleteSuggestions = {
  deleteRecentSearchSuggestions: VoidFunction
}

export const useDeleteAutocompleteSuggestions = (): SearchAutocompleteDeleteSuggestions => {
  const searchSuggestionsSdk = useSearchSuggestionsSdk()
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()

  const { add: addSnackbarMessage } = useSnackbar()

  const { mutate } = useMutation<void, SdkError, void>(() => searchSuggestionsSdk.deleteAll(), {
    onSuccess: () => {
      addSnackbarMessage({
        severity: 'positive',
        content: formatMessage({ id: 'delete_all_searches_confirmation_title' }),
      })
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.searchAutocompleteSuggestions],
      })
    },
    onError: () => {
      addSnackbarMessage({
        severity: 'alert',
        content: formatMessage({ id: 'delete_all_searches_fail_title' }),
      })
    },
  })

  return {
    deleteRecentSearchSuggestions: mutate,
  }
}
