import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rgba } from 'polished'

import { spacing, SPACING_3, SPACING_7 } from '@DS/styles/constants/spacing'
import { Z_INDEX_MEDIUM_HIGH } from '@Styles/constants/layout'
import { SLIDE_TRANSITION } from '@Styles/constants/transition'
import { resetListItems } from '@Styles/list'

import { CAROUSEL_MULTILINE_COLUMN_WIDTH, CAROUSEL_MULTILINE_MAX_ITEMS_PER_LINE } from './Carousel.constants'
import { CarouselMediumUpGridDisplayRules } from './Carousel.types'

const gradientCommonStyles: SerializedStyles = css({
  bottom: 0,
  content: "''",
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  transition: SLIDE_TRANSITION,
  zIndex: Z_INDEX_MEDIUM_HIGH,
})

const gradientBeforeStyles = (theme: Theme, width: string, isElevated = false): SerializedStyles =>
  css(gradientCommonStyles, {
    background: `linear-gradient(90deg, ${rgba(
      isElevated ? theme.palette.background.paper : theme.palette.background.default,
      1
    )} 50%, ${rgba(isElevated ? theme.palette.background.paper : theme.palette.background.default, 0)} 100%)`,
    left: 0,
    width,
  })

const gradientAfterStyles = (theme: Theme, width: string, isElevated = false): SerializedStyles =>
  css(gradientCommonStyles, {
    background: `linear-gradient(90deg, ${rgba(
      isElevated ? theme.palette.background.paper : theme.palette.background.default,
      0
    )} 0%, ${rgba(isElevated ? theme.palette.background.paper : theme.palette.background.default, 1)} 50%)`,
    right: 0,
    width,
  })

export const wrapper = (
  theme: Theme,
  {
    spacingAround,
    isScrolled = false,
    isScrolledToEnd = false,
    isElevated = false,
    mediumUpGridDisplayRules,
  }: {
    spacingAround: number
    isScrolled?: boolean
    isScrolledToEnd?: boolean
    isElevated?: boolean
    mediumUpGridDisplayRules?: CarouselMediumUpGridDisplayRules
  }
): SerializedStyles =>
  css(
    {
      padding: `${spacing(spacingAround)} 0`,
    },
    {
      [mediumUpGridDisplayRules ? theme.breakpoints.down('md') : '&']: {
        '&::after': gradientAfterStyles(theme, isScrolledToEnd ? '0' : SPACING_7, isElevated),
        '&::before': gradientBeforeStyles(theme, isScrolled ? SPACING_7 : '0', isElevated),
        position: 'relative',
        transform: `translateX(-${spacing(spacingAround)})`,
        width: `calc(100% + ${spacing(spacingAround * 2)})`,
      },
    }
  )

export const carouselContainer = (
  theme: Theme,
  {
    spacingAround,
    itemsGap,
    areItemsCentered = false,
    isScrollSnapDisabled = false,
    mediumUpGridDisplayRules,
    isMouseScrollDisabled = false,
  }: {
    spacingAround: number
    itemsGap: number
    areItemsCentered?: boolean
    isScrollSnapDisabled?: boolean
    mediumUpGridDisplayRules?: CarouselMediumUpGridDisplayRules
    isMouseScrollDisabled?: boolean
  }
): SerializedStyles =>
  css(
    resetListItems,
    {
      [mediumUpGridDisplayRules ? theme.breakpoints.down('md') : '&']: {
        '& > *:not(:last-child)': {
          marginRight: spacing(itemsGap),
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '> *': {
          flexShrink: 0,
          scrollSnapAlign: 'center',
        },
        ...(areItemsCentered && {
          alignItems: 'center',
        }),
        display: 'flex',
        overflow: isMouseScrollDisabled ? 'hidden' : 'auto',
        padding: `0 ${spacing(spacingAround)}`,
        scrollSnapType: isScrollSnapDisabled ? 'none' : 'x mandatory',
      },
    },
    mediumUpGridDisplayRules && {
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gap: SPACING_3,
        gridTemplateColumns: `repeat(${mediumUpGridDisplayRules.mdColumnsCount},1fr)`,
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: `repeat(${mediumUpGridDisplayRules.lgColumnsCount},1fr)`,
      },
    }
  )

export const multilineCarouselWrapper = (
  theme: Theme,
  {
    spacingAround,
    isScrolled = false,
    isScrolledToEnd = false,
    isElevated = false,
  }: {
    spacingAround: number
    isScrolled: boolean
    isScrolledToEnd: boolean
    isElevated: boolean
  }
): SerializedStyles => css(wrapper(theme, { isElevated, isScrolled, isScrolledToEnd, spacingAround }), { padding: 0 })

export const multilineCarouselContainer = (
  theme: Theme,
  { spacingAround, areItemsCentered = false }: { spacingAround: number; areItemsCentered: boolean }
): SerializedStyles =>
  css(resetListItems, carouselContainer(theme, { areItemsCentered, itemsGap: 0, spacingAround }), {
    '&': {
      display: 'grid',
      gridTemplateColumns: `repeat(${CAROUSEL_MULTILINE_MAX_ITEMS_PER_LINE}, ${CAROUSEL_MULTILINE_COLUMN_WIDTH})`, // differ
      paddingLeft: spacing(spacingAround),
      width: '100%',
    },
  })

export const breakLineItem = css({ gridColumnStart: 1 })

export const ulListItem = css({
  flexGrow: 1,
})
