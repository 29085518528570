import { FC, useState } from 'react'
import { useIntl } from 'react-intl'

import Dialog from '@Components/designSystem/components/dialog/Dialog'
import AutocompleteResult from '@Components/designSystem/navigation/search/autocompleteResult/AutocompleteResult'
import { useFeatures } from '@Contexts/features/FeaturesContext'
import { useDeleteAutocompleteSuggestions } from '@Hooks/autocomplete/useDeleteAutocompleteSuggestions'
import { AutocompleteResultModel } from '@Utils/autocomplete'

import { AutocompleteBaseProps } from './Autocomplete.types'
import GenericAutocomplete from './GenericAutocomplete'

export type AutocompleteProps = AutocompleteBaseProps<AutocompleteResultModel>

const Autocomplete: FC<AutocompleteProps> = ({ autocompleteRef, children, ...props }) => {
  const { isIvts62441RecentSearchDeleteAllEnabled } = useFeatures()
  const [isRecentSearchSupressionDialogOpen, setRecentSearchSupressionDialogOpen] = useState(false)
  const { formatMessage } = useIntl()
  const { deleteRecentSearchSuggestions } = useDeleteAutocompleteSuggestions()

  const handleOpenDeleteRecentSearchSuggestionsDialog = () => {
    setRecentSearchSupressionDialogOpen(true)
  }

  const handleCloseDeleteRecentSearchSuggestionsDialog = () => {
    setRecentSearchSupressionDialogOpen(false)
  }

  return (
    <>
      <GenericAutocomplete
        AutocompleteItemComponent={AutocompleteResult}
        {...props}
        ref={autocompleteRef}
        onClickDeleteRecentSearchSuggestions={handleOpenDeleteRecentSearchSuggestionsDialog}
      >
        {children}
      </GenericAutocomplete>
      {isIvts62441RecentSearchDeleteAllEnabled && (
        <Dialog
          show={isRecentSearchSupressionDialogOpen}
          message={formatMessage({ id: 'delete_all_searches' })}
          onClose={handleCloseDeleteRecentSearchSuggestionsDialog}
          defaultAction={{
            onClick: handleCloseDeleteRecentSearchSuggestionsDialog,
            label: formatMessage({ id: 'no' }),
          }}
          actions={[
            {
              label: formatMessage({ id: 'delete_all_searches_confirmation_action_title' }),
              onClick: deleteRecentSearchSuggestions,
            },
          ]}
        />
      )}
    </>
  )
}

export default Autocomplete
