import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { SnackbarContext } from '@Contexts/snackbar/SnackbarContext'
import { SnackbarMessageOptions } from '@Contexts/snackbar/SnackbarContext.types'
import { SnackbarMessage } from '@DS/components/components/snackbar/Snackbar.types'

export const SnackbarWidgetProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const [queue, setQueue] = useState<{ message: SnackbarMessage; options?: SnackbarMessageOptions }[]>([])
  const [message, setMessage] = useState<SnackbarMessage | undefined>(undefined)

  const close = useCallback(() => {
    setMessage(undefined)
    setOpen(false)
  }, [])

  const add = useCallback((newMessage: SnackbarMessage, messageSettings?: SnackbarMessageOptions) => {
    setQueue((prev) => [
      ...prev,
      { message: { key: new Date().getTime().toString(), ...newMessage }, options: messageSettings },
    ])
  }, [])

  useEffect(() => {
    if (!queue.length) {
      return
    }

    if (!message) {
      setMessage(queue[0].message)
      setQueue((prev) => prev.slice(1))
      setOpen(true)

      return
    }

    if (isOpen) {
      close()
    }
  }, [queue, isOpen, message, close])

  const value = useMemo(
    () => ({
      add,
      isOpen,
      message,
      close,
    }),
    [add, close, isOpen, message]
  )

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
}
