import { FC } from 'react'

import { DEFAULT_SNAP_COLOR } from '@Components/designSystem/illustrations/Illustration.utils'
import { SnapIllustrationProps } from '@Components/designSystem/illustrations/types'
import { ReactComponent as ProactivityMediumUp } from '@Svg/illustrations/snap/mediumUp/tutoBig.svg'
import { ReactComponent as ProactivitySmallMediumUp } from '@Svg/illustrations/snap/mediumUp/tutoSmall.svg'
import { ReactComponent as ProactivitySmall } from '@Svg/illustrations/snap/small/tuto.svg'

import SnapBase from '../_internal/SnapBase'

export type SnapTutoProps = SnapIllustrationProps<'onAlternativeColor1' | 'onAlternativeColor2' | 'service' | 'info'>

const SnapTuto: FC<SnapTutoProps> = ({ isSmall, ...rest }) => (
  <SnapBase
    {...rest}
    defaultColorName={DEFAULT_SNAP_COLOR}
    desktopIllustration={isSmall ? ProactivitySmallMediumUp : ProactivityMediumUp}
    illustration={ProactivitySmall}
    isSmall={isSmall}
  />
)

export default SnapTuto
