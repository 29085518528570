import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_0, SPACING_1, SPACING_2 } from '@Styles/constants/spacing'
import { getFocusVisibleIconButtonStyle, getFocusVisibleStyle } from '@Styles/focus'

export const close = (theme: Theme, isFullWidth: boolean, focusColor: CSSProperties['color']): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleIconButtonStyle(focusColor, rem(8)),
    position: 'absolute',
    right: rem(6),
    top: rem(6),
    ...(!isFullWidth && {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        right: SPACING_0,
        top: 0,
      },
    }),
  })

export const closeIcon = css({
  ...size(rem(24)),
})

export const actionLink = (focusColor: CSSProperties['color']): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleStyle(focusColor),
    '&:hover': {
      color: 'inherit',
    },
    padding: SPACING_2,
  })

export const actionButton = (theme: Theme, isFullWidth: boolean): SerializedStyles =>
  css({
    margin: SPACING_2,
    maxWidth: 'unset',
    [theme.breakpoints.up('sm')]: {
      margin: `${SPACING_1} ${SPACING_2} ${SPACING_2}`,
    },
    ...(!isFullWidth && {
      [theme.breakpoints.up('md')]: {
        margin: `auto ${SPACING_1}`,
        paddingBottom: 0,
        paddingTop: 0,
      },
    }),
  })

export const counterIcon = css({
  ...size(rem(16)),
  marginRight: SPACING_0,
})

export const counterContainer = (theme: Theme, isFullWidth: boolean): SerializedStyles =>
  css({
    alignItems: 'self-end',
    display: 'flex',
    marginBottom: SPACING_2,
    marginRight: SPACING_2,
    ...(!isFullWidth && {
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        marginLeft: SPACING_1,
        marginTop: SPACING_2,
      },
    }),
  })

export const counterText = css({
  marginBottom: `-${rem(2)}`,
})
