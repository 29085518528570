// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const GoogleWalletButtonDe = svgLazyLoader('walletButton/google_wallet_button_de')
const GoogleWalletButtonEn = svgLazyLoader('walletButton/google_wallet_button_en')
const GoogleWalletButtonEs = svgLazyLoader('walletButton/google_wallet_button_es')
const GoogleWalletButtonFr = svgLazyLoader('walletButton/google_wallet_button_fr')
const GoogleWalletButtonIt = svgLazyLoader('walletButton/google_wallet_button_it')
const GoogleWalletButtonNl = svgLazyLoader('walletButton/google_wallet_button_nl')

const ALL_WALLET_BUTTON_ICONS = [
  'google_wallet_button_de',
  'google_wallet_button_en',
  'google_wallet_button_es',
  'google_wallet_button_fr',
  'google_wallet_button_it',
  'google_wallet_button_nl',
] as const
export type WalletButtonIcon = (typeof ALL_WALLET_BUTTON_ICONS)[number]

const isWalletButtonIcon = (walletButtonKey: string): walletButtonKey is WalletButtonIcon =>
  ALL_WALLET_BUTTON_ICONS.includes(walletButtonKey as WalletButtonIcon)

const ICONS: LazyIconsGroup<WalletButtonIcon> = {
  google_wallet_button_de: GoogleWalletButtonDe,
  google_wallet_button_en: GoogleWalletButtonEn,
  google_wallet_button_es: GoogleWalletButtonEs,
  google_wallet_button_fr: GoogleWalletButtonFr,
  google_wallet_button_it: GoogleWalletButtonIt,
  google_wallet_button_nl: GoogleWalletButtonNl,
}

export const getWalletButtonIcon = (walletButtonKey: string | undefined): LazyIcon | undefined =>
  walletButtonKey && isWalletButtonIcon(walletButtonKey) ? ICONS[walletButtonKey] : undefined

export const getWalletButtonIcons = (): LazyIconsGroup<WalletButtonIcon> => ICONS
