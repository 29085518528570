import {
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react'
import { Card, CardProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useFeatures } from '@Contexts/features/FeaturesContext'
import { CommonCardBackground } from '@DS/components/foundations/shapes/card/types'

import * as commonStyles from '../styles'

import * as styles from './styles'

export type BasicCardProps<Component extends ElementType = 'div'> = Omit<CardProps<Component>, 'children'> & {
  hasError?: boolean
  borderColor?: CSSProperties['borderColor']
  background?: CommonCardBackground
  backgroundColor?: CSSProperties['backgroundColor']
  children?: ReactNode
  isFullWidth?: boolean
} & ComponentPropsWithoutRef<Component> & { component?: Component }

const BasicCard = forwardRef(
  <Component extends ElementType>(
    {
      children,
      background = 'paper',
      backgroundColor,
      hasError = false,
      borderColor,
      isFullWidth = false,
      ...rest
    }: BasicCardProps<Component>,
    ref: ForwardedRef<HTMLDivElement>
  ): ReactElement | null => {
    const theme = useTheme()
    const { isIvts40073WebMutualisationMediaCardsEnabled, isIvts78795FixTripcardHighlightBorderEnabled } = useFeatures()

    return (
      <Card
        ref={ref}
        {...rest}
        css={[
          isIvts40073WebMutualisationMediaCardsEnabled && isFullWidth ? styles.basicCardFullWidth : undefined,
          backgroundColor ? styles.backgroundColor(backgroundColor) : commonStyles.cardBackground(theme, background),
          borderColor && styles.borderColor(borderColor, isIvts78795FixTripcardHighlightBorderEnabled),
          hasError && styles.borderColor(theme.palette.error.main, isIvts78795FixTripcardHighlightBorderEnabled),
        ]}
      >
        {children}
      </Card>
    )
  }
)

export default BasicCard
