import { FC } from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ItineraryInfo } from 'invictus-sdk-typescript'

import { getDsIcon } from '@Components/designSystem/icons/dsIcons'
import * as styles from '@Components/designSystem/navigation/search/autocompleteResult/recentSearchResult/styles'
import * as mainStyles from '@Components/designSystem/navigation/search/autocompleteResult/styles'

type ItineraryRecentSearchProps = {
  label: string
  itineraryInformation?: ItineraryInfo
  description?: string
}

const ItineraryResult: FC<ItineraryRecentSearchProps> = ({ label, itineraryInformation, description }) => {
  const theme = useTheme()

  if (!itineraryInformation) {
    return <span />
  }

  const TravelTypeIcon = getDsIcon({
    iconName: itineraryInformation.travelTypeIconId.iconName,
    iconGroup: itineraryInformation.travelTypeIconId.iconGroup,
  })

  return (
    <>
      <div css={styles.recentSearchResultContainer}>
        <Typography component="div" variant="body1" css={styles.recentSearchLabel} aria-hidden>
          {label}
        </Typography>
        <div css={styles.recentSearchResultDestinationContainer}>
          {!!TravelTypeIcon && <TravelTypeIcon css={[styles.recentSearchInwardIcon, mainStyles.placeIcon(theme)]} />}
          <Typography component="div" variant="body1" css={styles.recentSearchLabel} aria-hidden>
            {itineraryInformation.destinationLabel}
          </Typography>
        </div>
      </div>
      {!!description?.length && (
        <div css={styles.recentSearchDescriptionContainer}>
          <Typography component="span" css={styles.recentSearchDescription(theme)} variant="caption" aria-hidden>
            {description}
          </Typography>
        </div>
      )}
    </>
  )
}

export default ItineraryResult
