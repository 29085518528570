import { FC, HTMLAttributes } from 'react'
import { useTheme } from '@mui/material/styles'
import { ShortItineraryIconGenerate } from 'invictus-sdk-typescript'

import * as styles from '../styles'

type GeneratedTransportIconProps = ShortItineraryIconGenerate & {
  isSmallGenerated?: boolean
} & HTMLAttributes<HTMLSpanElement>

const GeneratedTransportIcon: FC<GeneratedTransportIconProps> = ({
  color,
  radius,
  label,
  textColor,
  isSmallGenerated = false,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <span css={styles.generatedTransportIcon(theme, color, textColor, radius, isSmallGenerated)} {...rest}>
      {label}
    </span>
  )
}

export default GeneratedTransportIcon
