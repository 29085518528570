import { ElementType, forwardRef, ReactNode } from 'react'
import { SerializedStyles } from '@emotion/react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Variant } from '@mui/material/styles/createTypography'

import { HighlightCardVariant } from '@Components/designSystem/foundations/shapes/card/types'
import { AreaIcon } from '@Components/designSystem/icons/areaIcons'
import { DiversIcon, getDiversIcon } from '@Components/designSystem/icons/diversIcons'
import { LazyIcon } from '@Components/designSystem/icons/icons.types'
import { MobilityIcon } from '@Components/designSystem/icons/mobilityIcons'
import { TagSize, TagVariant } from '@Types/tag'

import * as styles from './styles'

type IsHighlightCardVariant = {
  isOnHighlightCard: true
  variant: HighlightCardVariant
}

type IsTagVariant = {
  isOnHighlightCard: false
  variant: TagVariant
}

type TagVariantMode = IsHighlightCardVariant | IsTagVariant

export type TagProps = {
  children: ReactNode
  component?: ElementType
  componentCssProps?: SerializedStyles
  size?: TagSize
  withIcon?: boolean
  isOnButton?: boolean
  isFullWidth?: boolean
  icon?: LazyIcon
  mode: TagVariantMode
}

const VARIANT_SIZES: Record<TagSize, Variant> = {
  large: 'body1',
  small: 'subtitle1',
}

const ICONS: Record<TagVariant, DiversIcon | MobilityIcon | AreaIcon> = {
  promo: 'deals_full',
  proactivity: 'lightning_bolt_full',
  tips: 'tip_full',
  travel: 'suitcase_full',
  warning: 'suitcase_full',
  neutral: 'suitcase_full',
  success: 'suitcase_full',
  canceled: 'suitcase_full',
}

const isOnHighlightCardVariant = (mode: TagVariantMode): mode is IsHighlightCardVariant => mode.isOnHighlightCard

const Tag = forwardRef<HTMLDivElement, TagProps>(
  (
    {
      children,
      mode,
      component = 'p',
      componentCssProps,
      size = 'small',
      withIcon = false,
      isOnButton = false,
      isFullWidth = false,
      icon,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme()

    const Icon = icon || (withIcon && getDiversIcon(ICONS[mode.variant]))
    const hasIcon = !!Icon
    const isLarge = size === 'large'

    return (
      <div ref={ref} css={[styles.tag(isLarge, isFullWidth), styles.colors(theme, mode.variant)]} {...rest}>
        {hasIcon && (
          <div css={styles.iconWrapper}>
            <Icon css={styles.icon(isLarge)} />
          </div>
        )}

        <div css={styles.contentWrapper(isLarge, isFullWidth)}>
          <Typography
            css={[
              styles.content(theme, isLarge, hasIcon, isFullWidth),
              isOnHighlightCardVariant(mode)
                ? styles.highlightBackgroundColors(theme, mode.variant, isOnButton)
                : styles.backgroundColors(theme, mode.variant, isOnButton),
              componentCssProps,
            ]}
            component={component}
            variant={VARIANT_SIZES[size]}
          >
            {children}
          </Typography>
        </div>
      </div>
    )
  }
)

export default Tag
