import { FC } from 'react'

import { IllustrationBaseProps } from '@Components/designSystem/illustrations/types'
import { ReactComponent as SystemErrorMediumUp } from '@Svg/illustrations/moment/mediumUp/system_error.svg'
import { ReactComponent as SystemErrorSmall } from '@Svg/illustrations/moment/small/system_error.svg'

import MomentBase from '../_internal/MomentBase'

const MomentSystemError: FC<IllustrationBaseProps<'error' | 'warning' | 'onAlternativeColor1'>> = (props) => (
  <MomentBase
    {...props}
    defaultColorName="error"
    desktopIllustration={SystemErrorMediumUp}
    illustration={SystemErrorSmall}
  />
)

export default MomentSystemError
