import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { rem } from 'polished'

import { QUARTER_RADIUS } from '@Styles/constants/radius'

export const scrollbarTheming = (
  scrollbarColor: CSSProperties['color'],
  scrollbarThumbColor: CSSProperties['color']
): SerializedStyles =>
  css({
    '&::-webkit-scrollbar': {
      backgroundColor: scrollbarColor,
      width: rem(8),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarThumbColor,
      borderRadius: QUARTER_RADIUS,
    },
    scrollbarColor: `${scrollbarThumbColor} ${scrollbarColor}`,
    scrollbarWidth: 'thin',
  })
