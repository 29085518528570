import { FC } from 'react'
import { useIntl } from 'react-intl'
import { CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AutocompleteTutorial } from 'invictus-sdk-typescript'

import BasicCard, { BasicCardProps } from '@DS/components/foundations/shapes/card/basic/BasicCard'
import SnapTuto from '@DS/components/illustrations/snap/tuto/SnapTuto'
import { useIsMediumUp } from '@Hooks/useResponsive'
import { ReactComponent as CloseAndroidDark } from '@Svg/native/close_android_dark.svg'

import TagsTuto from './TagsTuto'
import * as styles from './styles'

type TutoSearchProps = Omit<BasicCardProps, 'background' | 'backgroundColor'> & {
  autocompleteTutorial: AutocompleteTutorial
  onClose: VoidFunction
}

const TutoSearch: FC<TutoSearchProps> = ({ autocompleteTutorial: { mainLabel, examples }, onClose, ...rest }) => {
  const theme = useTheme()

  const isMediumUp = useIsMediumUp(theme)
  const { formatMessage } = useIntl()

  return (
    <BasicCard {...rest} backgroundColor={theme.customPalette.alternative.service}>
      <CardHeader
        action={
          <IconButton
            aria-label={formatMessage({ id: 'searchTutorial_suggestionCloseButton' })}
            data-cs-override-id="search_push-tuto_close"
            data-test="autocomplete_tuto_search_button_close"
            onClick={onClose}
            size="large"
          >
            <CloseAndroidDark css={styles.closeIcon(theme)} />
          </IconButton>
        }
        avatar={<SnapTuto css={styles.illuTutoIcon(theme)} isSmall />}
        css={styles.cardHeader(theme)}
        title={<Typography variant="body1">{mainLabel}</Typography>}
        subheader={isMediumUp ? <TagsTuto tags={examples} /> : undefined}
      />
      {!isMediumUp && (
        <CardContent css={styles.cardContent}>
          <TagsTuto tags={examples} />
        </CardContent>
      )}
    </BasicCard>
  )
}

export default TutoSearch
