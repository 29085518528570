// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const ArrowBackAndroid = svgLazyLoader('native/arrow_back_android')
const ArrowBackIos = svgLazyLoader('native/arrow_back_ios')
const BackIos = svgLazyLoader('native/back_ios')
const BackMaterial = svgLazyLoader('native/back_material')
const CloseAndroidDark = svgLazyLoader('native/close_android_dark')
const CloseIos = svgLazyLoader('native/close_ios')
const CloseMaterial = svgLazyLoader('native/close_material')
const RefreshMaterial = svgLazyLoader('native/refresh_material')
const Remove = svgLazyLoader('native/remove')

const ALL_NATIVE_ICONS = [
  'arrow_back_android',
  'arrow_back_ios',
  'back_ios',
  'back_material',
  'close_android_dark',
  'close_ios',
  'close_material',
  'refresh_material',
  'remove',
] as const
export type NativeIcon = (typeof ALL_NATIVE_ICONS)[number]

const isNativeIcon = (nativeKey: string): nativeKey is NativeIcon => ALL_NATIVE_ICONS.includes(nativeKey as NativeIcon)

const ICONS: LazyIconsGroup<NativeIcon> = {
  arrow_back_android: ArrowBackAndroid,
  arrow_back_ios: ArrowBackIos,
  back_ios: BackIos,
  back_material: BackMaterial,
  close_android_dark: CloseAndroidDark,
  close_ios: CloseIos,
  close_material: CloseMaterial,
  refresh_material: RefreshMaterial,
  remove: Remove,
}

export const getNativeIcon = (nativeKey: string | undefined): LazyIcon | undefined =>
  nativeKey && isNativeIcon(nativeKey) ? ICONS[nativeKey] : undefined

export const getNativeIcons = (): LazyIconsGroup<NativeIcon> => ICONS
