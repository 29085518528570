import { css } from '@emotion/react'
import { rem, size } from 'polished'

import { SPACING_1 } from '@DS/styles/constants/spacing'

export const button = css({
  '& > svg': {
    ...size(rem(24)),
    marginLeft: SPACING_1,
  },
  alignItems: 'end',
  justifyContent: 'center',
})
