import { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { SnackbarMessage } from '@Components/designSystem/components/snackbar/Snackbar.types'
import { SnackbarContextType, SnackbarMessageOptions } from '@Contexts/snackbar/SnackbarContext.types'
import useOnRouteChange from '@Hooks/useOnRouteChange'

export const SnackbarContext = createContext<SnackbarContextType | null>(null)

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const [queue, setQueue] = useState<{ message: SnackbarMessage; options?: SnackbarMessageOptions }[]>([])
  const [message, setMessage] = useState<SnackbarMessage | undefined>(undefined)
  const [messageOptions, setMessageOptions] = useState<SnackbarMessageOptions | undefined>(undefined)

  const close = useCallback(() => {
    setMessage(undefined)
    setMessageOptions(undefined)
    setOpen(false)
  }, [])

  const handleClose = useCallback(() => {
    if (!messageOptions?.keepOpenOnRouteChange) {
      close()
    }
  }, [close, messageOptions])

  useOnRouteChange({ routerEvent: 'routeChangeComplete', onRouteChangeHandler: handleClose })

  const add = useCallback((newMessage: SnackbarMessage, messageSettings?: SnackbarMessageOptions) => {
    setQueue((prev) => [
      ...prev,
      { message: { key: new Date().getTime().toString(), ...newMessage }, options: messageSettings },
    ])
  }, [])

  useEffect(() => {
    if (!queue.length) {
      return
    }

    if (!message) {
      setMessage(queue[0].message)
      setMessageOptions(queue[0].options)
      setQueue((prev) => prev.slice(1))
      setOpen(true)

      return
    }

    if (isOpen) {
      close()
    }
  }, [queue, isOpen, message, close])

  const value = useMemo(
    () => ({
      add,
      isOpen,
      message,
      close,
    }),
    [add, close, isOpen, message]
  )

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
}
