import { useContext } from 'react'

import { SnackbarContext } from '@Contexts/snackbar/SnackbarContext'
import { SnackbarContextType } from '@Contexts/snackbar/SnackbarContext.types'

type SnackBarType = Pick<SnackbarContextType, 'add' | 'close' | 'isOpen'>

const useSnackbar = (): SnackBarType => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar has to be used within SnackbarContext')
  }

  return {
    add: context.add,
    isOpen: context.isOpen,
    close: context.close,
  }
}

export default useSnackbar
