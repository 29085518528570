import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  AutocompleteVisitCount,
  isAutocompleteVisitCount,
  SearchSuggestionsResult,
  SearchType,
  SuggestionCard,
  SuggestionTutorial,
} from 'invictus-sdk-typescript'

import { LOCAL } from '@Constants/localStorage'
import { SESSION } from '@Constants/sessionStorage'
import { AutocompleteSection } from '@DS/components/navigation/search/autocomplete/Autocomplete.types'
import { useLocalStorage } from '@Hooks/useLocalStorage'
import { useSessionStorage } from '@Hooks/useSessionStorage'
import { AutocompleteResultModel } from '@Utils/autocomplete'

import { useSearchAutocompleteSuggestionsQuery } from './useSearchAutocompleteQuery'

type SearchAutocompleteSuggestions = [
  suggestions: AutocompleteSection<AutocompleteResultModel>[],
  tuto: {
    autocompleteTutorial: SuggestionTutorial | undefined
    olympicGamesTips: SuggestionCard | undefined
    hasToDisplayTuto: boolean | undefined
    hasToDisplayOlympicGamesTips: boolean | undefined
    closeTuto: VoidFunction
    closeOlympicGamesTips: VoidFunction
  }
]

const incrementTutorialVisitCount = (
  actualVisitCount: AutocompleteVisitCount | undefined | null
): AutocompleteVisitCount | null =>
  new Map<AutocompleteVisitCount | undefined | null, AutocompleteVisitCount | null>([
    [undefined, 0],
    [0, 1],
    [1, 2],
    [2, null],
    [null, null],
  ]).get(actualVisitCount) ?? null

const defaultEmptyHandler = () => {}
const defaultEmptyObject = {}

export const useSearchAutocompleteSuggestions: (
  searchType: SearchType[],
  options?: { withTuto?: boolean; shouldIgnoreSuggestions?: boolean }
) => SearchAutocompleteSuggestions = (
  searchType,
  { withTuto = false, shouldIgnoreSuggestions = false } = defaultEmptyObject
) => {
  const [suggestions, setSuggestions] = useState<AutocompleteSection<AutocompleteResultModel>[]>([])
  const [autocompleteTutorial, setAutocompleteTutorial] = useState<SuggestionTutorial>()
  const [olympicGamesTips, setOlympicGamesTips] = useState<SuggestionCard>()

  const { get: getAutocompleteTutorialDisplay, set: setAutocompleteTutorialDisplay } = useSessionStorage<
    boolean | undefined
  >(SESSION.autocompleteTutorialDisplay)
  const autocompleteTutorialDisplay = getAutocompleteTutorialDisplay()

  const { get: getShouldDisplayAutocompleteOlympicGamesTips, set: setShouldDisplayAutocompleteOlympicGamesTips } =
    useLocalStorage<boolean | undefined>(LOCAL.AUTOCOMPLETE_OLYMPIC_GAMES_TIPS_DISPLAY)

  const { get: getAutocompleteTutorialVisitCount, set: setAutocompleteTutorialVisitCount } =
    useLocalStorage<AutocompleteVisitCount | null>(LOCAL.AUTOCOMPLETE_TUTORIAL_VISIT_COUNT, 0)

  const { formatMessage } = useIntl()

  const mapSearchSuggestionsResultToAutocompleteSections = useCallback(
    (result: SearchSuggestionsResult): AutocompleteSection<AutocompleteResultModel>[] => [
      {
        section: 'recentSearches',
        title: formatMessage({ id: 'recentSearches' }),
        items: result.recentSearchesV2.length > 0 ? result.recentSearchesV2 : result.recentSearches,
      },
    ],
    [formatMessage]
  )

  const isReady = useMemo(
    () => autocompleteTutorialDisplay !== undefined && getAutocompleteTutorialVisitCount() !== undefined,
    [autocompleteTutorialDisplay, getAutocompleteTutorialVisitCount]
  )
  const isSet = useMemo(() => autocompleteTutorial !== undefined, [autocompleteTutorial])

  const autocompleteVisitCount = autocompleteTutorialDisplay
    ? getAutocompleteTutorialVisitCount() ?? undefined
    : undefined

  const {
    isSuccess,
    data: remoteSuggestions,
    error,
  } = useSearchAutocompleteSuggestionsQuery(searchType, autocompleteVisitCount, {
    enabled: isReady && !isSet && !shouldIgnoreSuggestions,
  })

  useEffect(() => {
    if (isSuccess) {
      if (withTuto) {
        setAutocompleteTutorial(remoteSuggestions.tutorialSuggestions)
      }

      setOlympicGamesTips(remoteSuggestions.olympicGamesTips)

      setSuggestions(mapSearchSuggestionsResultToAutocompleteSections(remoteSuggestions))
    }
  }, [remoteSuggestions, isSuccess, error, mapSearchSuggestionsResultToAutocompleteSections, withTuto])

  useEffect(() => {
    const actualAutocompleteTutorialVisitCount = getAutocompleteTutorialVisitCount()

    if (autocompleteTutorialDisplay === undefined || isAutocompleteVisitCount(actualAutocompleteTutorialVisitCount)) {
      const newAutocompleteTutorialVisitCount = incrementTutorialVisitCount(actualAutocompleteTutorialVisitCount)
      setAutocompleteTutorialVisitCount(
        withTuto ? newAutocompleteTutorialVisitCount : actualAutocompleteTutorialVisitCount ?? 0
      )
      setAutocompleteTutorialDisplay(
        (autocompleteTutorialDisplay === true || autocompleteTutorialDisplay === undefined) &&
          isAutocompleteVisitCount(newAutocompleteTutorialVisitCount)
      )
    }
  }, [
    withTuto,
    autocompleteTutorialDisplay,
    getAutocompleteTutorialVisitCount,
    setAutocompleteTutorialVisitCount,
    setAutocompleteTutorialDisplay,
  ])

  return [
    suggestions,
    {
      autocompleteTutorial,
      olympicGamesTips,
      hasToDisplayTuto: withTuto && autocompleteTutorialDisplay,
      closeTuto: withTuto ? () => setAutocompleteTutorialDisplay(false) : defaultEmptyHandler,
      hasToDisplayOlympicGamesTips: getShouldDisplayAutocompleteOlympicGamesTips() ?? true,
      closeOlympicGamesTips: olympicGamesTips
        ? () => setShouldDisplayAutocompleteOlympicGamesTips(false)
        : defaultEmptyHandler,
    },
  ]
}
