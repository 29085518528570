import { AutocompleteFullTextSuggestion, HighlightedPlace, RecentSearch } from 'invictus-sdk-typescript'

import { AutocompleteSection } from '@Components/designSystem/navigation/search/autocomplete/Autocomplete.types'
import { RecentSearchPlaceExtended } from '@Components/designSystem/navigation/search/autocompleteResult/inputProposalResult/AutcompleteInputProposalResult'

export type AutocompleteResultModel =
  | HighlightedPlace
  | RecentSearchPlaceExtended
  | AutocompleteFullTextSuggestion
  | RecentSearch

export const isRecentSearch = (model: AutocompleteResultModel): model is RecentSearch =>
  'autocompleteItemType' in model && model.autocompleteItemType === 'RECENT_SEARCH'

export const getIsPlace = (model: AutocompleteResultModel): model is HighlightedPlace | RecentSearchPlaceExtended =>
  !!(model as Exclude<AutocompleteResultModel, AutocompleteFullTextSuggestion | RecentSearch>).type

export const getIsFullTextSuggestion = (model: AutocompleteResultModel): model is AutocompleteFullTextSuggestion =>
  !!(model as AutocompleteFullTextSuggestion).iconId

export const hasRecentSearch = (suggestionsSections: AutocompleteSection[]): boolean =>
  suggestionsSections.some(
    (suggestionsSection) => suggestionsSection.section === 'recentSearches' && suggestionsSection.items.length > 0
  )
