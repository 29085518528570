import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { InputPlaceOptions } from '@Components/designSystem/navigation/search/common/ODSearch.types'
import { highContrast } from '@Styles/a11y'
import { Z_INDEX_HIGH, Z_INDEX_LOW } from '@Styles/constants/layout'
import { STANDARD_RADIUS } from '@Styles/constants/radius'
import { spacing, SPACING_1, SPACING_2, SPACING_3 } from '@Styles/constants/spacing'

export const autocompleteResult = (theme: Theme, isHighlighted: boolean): SerializedStyles =>
  css({
    ...(isHighlighted && {
      ...highContrast({
        border: 'solid 2px transparent',
      }),
      backgroundColor: theme.customPalette.onElements.selected,
    }),
    // TODO: gap: SPACING_2,
    '& > * + *': {
      marginLeft: SPACING_2,
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    outline: 'none',
    padding: `${SPACING_1} ${SPACING_2}`,
    [theme.breakpoints.up('md')]: {
      padding: `${SPACING_1} ${SPACING_3}`,
    },
  })

const inputPlaceAboveSeparatorOnFocus = (theme: Theme, hasError = false): SerializedStyles =>
  css({
    ':focus-within': {
      '& ~ hr': {
        zIndex: -1,
      },
      borderColor: hasError ? theme.palette.error.main : theme.palette.primary.main,
      boxShadow: `0 0 0 1px inset ${hasError ? theme.palette.error.main : theme.palette.primary.main}`,
    },
  })

export const inputPlace = (
  theme: Theme,
  { disabled = false, hasError = false, isSwapHidden = false }: InputPlaceOptions = {}
): SerializedStyles =>
  css(
    {
      ':hover': {
        backgroundColor: disabled ? theme.palette.background.paper : theme.customPalette.onElements.selected,
      },
      backgroundColor: theme.palette.background.paper,
      border: `1px solid transparent`,
      ...(hasError && {
        '& ~ hr': {
          zIndex: -1,
        },
        borderColor: theme.palette.error.main,
      }),
      color: theme.palette.text.primary,
      height: rem(48),
      padding: `0 ${isSwapHidden ? SPACING_2 : spacing(9)} 0 ${SPACING_2}`,
    },
    inputPlaceAboveSeparatorOnFocus(theme, hasError)
  )

export const odsearch = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: Z_INDEX_HIGH,
})

export const originInputPlace = css({
  borderRadius: `${STANDARD_RADIUS} ${STANDARD_RADIUS} 0 0`,
})

export const destinationInputPlace = css({
  borderRadius: `0 0 ${STANDARD_RADIUS} ${STANDARD_RADIUS}`,
})

const absolute = css({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: Z_INDEX_LOW,
})

export const separator = css(
  {
    width: '100%',
    ...highContrast({
      border: 'solid 1px transparent',
    }),
  },
  absolute
)

export const swap = css(
  {
    right: SPACING_2,
  },
  absolute
)
