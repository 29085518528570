import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { SPACING_1, SPACING_2, SPACING_3 } from '@DS/styles/constants/spacing'

export const content = css({
  display: 'flex',
  flexDirection: 'column',
  padding: `${SPACING_3} ${SPACING_3} ${SPACING_2}`,
})

export const illustration = (theme: Theme): SerializedStyles =>
  css({
    margin: 0,
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      margin: `0 0 ${SPACING_3} 0`,
      padding: 0,
      width: 'auto', // obligé de reindiquer la with: 'auto' sinon ça prend la surcharge du breakpoint "md" de MomentBase
    },
  })

export const annotations = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.secondary,
    paddingTop: SPACING_1,
    whiteSpace: 'pre-line',
  })

export const actions = css({
  alignSelf: 'flex-end',
  // Marges négatives pour les liens et boutons
  marginLeft: `calc(-${SPACING_2} + ${rem(1)})`,
  marginRight: `calc(-${SPACING_2} + ${rem(1)})`,
  marginTop: SPACING_3,
})

export const linksWrapper = (isColumn: boolean): SerializedStyles =>
  css({
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: isColumn ? 'column' : 'row',
  })
