// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Belgium = svgLazyLoader('country/belgium')
const Europe = svgLazyLoader('country/europe')
const EuropeMap = svgLazyLoader('country/europe_map')
const France = svgLazyLoader('country/france')
const Germany = svgLazyLoader('country/germany')
const Italy = svgLazyLoader('country/italy')
const Luxembourg = svgLazyLoader('country/luxembourg')
const Netherlands = svgLazyLoader('country/netherlands')
const Russia = svgLazyLoader('country/russia')
const Spain = svgLazyLoader('country/spain')
const Switzerland = svgLazyLoader('country/switzerland')
const Uk = svgLazyLoader('country/uk')

const ALL_COUNTRY_ICONS = [
  'belgium',
  'europe',
  'europe_map',
  'france',
  'germany',
  'italy',
  'luxembourg',
  'netherlands',
  'russia',
  'spain',
  'switzerland',
  'uk',
] as const
export type CountryIcon = (typeof ALL_COUNTRY_ICONS)[number]

const isCountryIcon = (countryKey: string): countryKey is CountryIcon =>
  ALL_COUNTRY_ICONS.includes(countryKey as CountryIcon)

const ICONS: LazyIconsGroup<CountryIcon> = {
  belgium: Belgium,
  europe: Europe,
  europe_map: EuropeMap,
  france: France,
  germany: Germany,
  italy: Italy,
  luxembourg: Luxembourg,
  netherlands: Netherlands,
  russia: Russia,
  spain: Spain,
  switzerland: Switzerland,
  uk: Uk,
}

export const getCountryIcon = (countryKey: string | undefined): LazyIcon | undefined =>
  countryKey && isCountryIcon(countryKey) ? ICONS[countryKey] : undefined

export const getCountryIcons = (): LazyIconsGroup<CountryIcon> => ICONS
