import { rem } from 'polished'

import { SPACING_5 } from '@Styles/constants/spacing'

/* Hauteur de l'appBar pour écran mobile */
export const APPBAR_HEIGHT = rem(56)
/* Hauteur de l'appBar pour écran tablette et desktop */
export const APPBAR_MD_HEIGHT = rem(72)
/* Hauteur avec appBar + tabBar */
export const HEIGHT_WITH_TABBAR_AND_APPBAR = `calc(100% - ${rem(136)})`
/* Offset top utile au conteneur sticky de droite du layout */
export const RIGHT_CONTAINER_TOP_OFFSET = `calc(${APPBAR_MD_HEIGHT} + ${SPACING_5})`
/* Hauteur NavBar */
export const NAVBAR_HEIGHT = rem(48)
export const NAVBAR_HEIGHT_MEDIUM_UP = rem(64)
/* Hauteur TabBar */
export const TABBAR_HEIGHT = rem(58)

export const Z_INDEX_LOW = 1
export const Z_INDEX_MEDIUM = 2
export const Z_INDEX_MEDIUM_HIGH = 3
export const Z_INDEX_HIGH = 4

export const Z_INDEX_NAVBAR_BACKDROP = 1200
export const Z_INDEX_HEADER = 1202
export const Z_INDEX_HEADER_MASK = 1201
export const Z_INDEX_AY11_SKIP_LINKS = 1201

export const STICKYBAR_HEIGHT = rem(48)
export const STICKYBAR_HEIGHT_MEDIUM_UP = rem(64)
