// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const AppstoreDe = svgLazyLoader('store/appstore_de')
const AppstoreEn = svgLazyLoader('store/appstore_en')
const AppstoreEs = svgLazyLoader('store/appstore_es')
const AppstoreFr = svgLazyLoader('store/appstore_fr')
const AppstoreIt = svgLazyLoader('store/appstore_it')
const AppstoreNl = svgLazyLoader('store/appstore_nl')
const GoogleplayDe = svgLazyLoader('store/googleplay_de')
const GoogleplayEn = svgLazyLoader('store/googleplay_en')
const GoogleplayEs = svgLazyLoader('store/googleplay_es')
const GoogleplayFr = svgLazyLoader('store/googleplay_fr')
const GoogleplayIt = svgLazyLoader('store/googleplay_it')
const GoogleplayNl = svgLazyLoader('store/googleplay_nl')

const ALL_STORE_ICONS = [
  'appstore_de',
  'appstore_en',
  'appstore_es',
  'appstore_fr',
  'appstore_it',
  'appstore_nl',
  'googleplay_de',
  'googleplay_en',
  'googleplay_es',
  'googleplay_fr',
  'googleplay_it',
  'googleplay_nl',
] as const

type StoreIcon = (typeof ALL_STORE_ICONS)[number]

const ICONS: LazyIconsGroup<StoreIcon> = {
  appstore_de: AppstoreDe,
  appstore_en: AppstoreEn,
  appstore_es: AppstoreEs,
  appstore_fr: AppstoreFr,
  appstore_it: AppstoreIt,
  appstore_nl: AppstoreNl,
  googleplay_de: GoogleplayDe,
  googleplay_en: GoogleplayEn,
  googleplay_es: GoogleplayEs,
  googleplay_fr: GoogleplayFr,
  googleplay_it: GoogleplayIt,
  googleplay_nl: GoogleplayNl,
}

export const getStoreIcons = (): LazyIconsGroup<StoreIcon> => ICONS
