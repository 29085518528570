// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const SncfconnectBot = svgLazyLoader('divers/sncfconnect_bot')
const Accessibility = svgLazyLoader('divers/accessibility')
const Bird = svgLazyLoader('divers/bird')
const CancelInsurance = svgLazyLoader('divers/cancel_insurance')
const CircleField = svgLazyLoader('divers/circle_field')
const CircleOutline = svgLazyLoader('divers/circle_outline')
const Co2 = svgLazyLoader('divers/co2')
const Co2Full = svgLazyLoader('divers/co2_full')
const Cookie = svgLazyLoader('divers/cookie')
const DarkLightMode = svgLazyLoader('divers/dark_light_mode')
const Deals = svgLazyLoader('divers/deals')
const DealsFull = svgLazyLoader('divers/deals_full')
const Euro = svgLazyLoader('divers/euro')
const EyeClose = svgLazyLoader('divers/eye_close')
const EyeOpen = svgLazyLoader('divers/eye_open')
const EyeVisuallyImpaired = svgLazyLoader('divers/eye_visually_impaired')
const FavoriteField = svgLazyLoader('divers/favorite_field')
const FavoriteOutline = svgLazyLoader('divers/favorite_outline')
const Feather = svgLazyLoader('divers/feather')
const Flag = svgLazyLoader('divers/flag')
const Flux = svgLazyLoader('divers/flux')
const LuggageBikeCover = svgLazyLoader('luggage/luggage_bike_cover')
const France = svgLazyLoader('divers/france')
const HardOfHearing = svgLazyLoader('divers/hard_of_hearing')
const Headphones = svgLazyLoader('divers/headphones')
const History = svgLazyLoader('divers/history')
const LightningBolt = svgLazyLoader('divers/lightning_bolt')
const LightningBoltFull = svgLazyLoader('divers/lightning_bolt_full')
const LineBlog = svgLazyLoader('divers/line_blog')
const Link = svgLazyLoader('divers/link')
const LuggageAtHome = svgLazyLoader('divers/luggage_at_home')
const Luggages = svgLazyLoader('divers/luggages')
const Medal = svgLazyLoader('divers/medal')
const MentalDisability = svgLazyLoader('divers/mental_disability')
const Number1 = svgLazyLoader('divers/number_1')
const Number2 = svgLazyLoader('divers/number_2')
const Number3 = svgLazyLoader('divers/number_3')
const Number4 = svgLazyLoader('divers/number_4')
const OnsiteActivities = svgLazyLoader('divers/onsite_activities')
const Padlock = svgLazyLoader('divers/padlock')
const RecentSearchTrip = svgLazyLoader('divers/recent_search_trip')
const Show = svgLazyLoader('divers/show')
const Snowflake = svgLazyLoader('divers/snowflake')
const Stadium = svgLazyLoader('divers/stadium')
const StarEmpty = svgLazyLoader('divers/star_empty')
const StarOutline = svgLazyLoader('divers/star_outline')
const Sun = svgLazyLoader('divers/sun')
const SunGlasses = svgLazyLoader('divers/sun_glasses')
const Terms = svgLazyLoader('divers/terms')
const TheMask = svgLazyLoader('divers/the_mask')
const ThumbUp = svgLazyLoader('divers/thumb_up')
const Tip = svgLazyLoader('divers/tip')
const TipFull = svgLazyLoader('divers/tip_full')
const Viewpoint = svgLazyLoader('divers/viewpoint')

const ALL_DIVERS_ICONS = [
  'sncfconnect_bot',
  'accessibility',
  'bird',
  'cancel_insurance',
  'circle_field',
  'circle_outline',
  'co2',
  'co2_full',
  'cookie',
  'dark_light_mode',
  'deals',
  'deals_full',
  'euro',
  'eye_close',
  'eye_open',
  'eye_visually-impaired',
  'favorite_field',
  'favorite_outline',
  'feather',
  'flag',
  'flux',
  'folded_bike_dim',
  'france',
  'hard_of_hearing',
  'headphones',
  'history',
  'lightning_bolt',
  'lightning_bolt_full',
  'line_blog',
  'link',
  'luggage_at_home',
  'luggages',
  'medal',
  'mental_disability',
  'number_1',
  'number_2',
  'number_3',
  'number_4',
  'onsite_activities',
  'padlock',
  'recent_search_trip',
  'show',
  'snowflake',
  'stadium',
  'star_empty',
  'star_outline',
  'sun',
  'sun_glasses',
  'terms',
  'the_mask',
  'thumb_up',
  'tip',
  'tip_full',
  'viewpoint',
] as const
export type DiversIcon = (typeof ALL_DIVERS_ICONS)[number]

const isDiversIcon = (diversKey: string): diversKey is DiversIcon => ALL_DIVERS_ICONS.includes(diversKey as DiversIcon)

const ICONS: LazyIconsGroup<DiversIcon> = {
  sncfconnect_bot: SncfconnectBot,
  accessibility: Accessibility,
  bird: Bird,
  cancel_insurance: CancelInsurance,
  circle_field: CircleField,
  circle_outline: CircleOutline,
  co2: Co2,
  co2_full: Co2Full,
  cookie: Cookie,
  dark_light_mode: DarkLightMode,
  deals: Deals,
  deals_full: DealsFull,
  euro: Euro,
  eye_close: EyeClose,
  eye_open: EyeOpen,
  'eye_visually-impaired': EyeVisuallyImpaired,
  favorite_field: FavoriteField,
  favorite_outline: FavoriteOutline,
  feather: Feather,
  flag: Flag,
  flux: Flux,
  folded_bike_dim: LuggageBikeCover,
  france: France,
  hard_of_hearing: HardOfHearing,
  headphones: Headphones,
  history: History,
  lightning_bolt: LightningBolt,
  lightning_bolt_full: LightningBoltFull,
  line_blog: LineBlog,
  link: Link,
  luggage_at_home: LuggageAtHome,
  luggages: Luggages,
  medal: Medal,
  mental_disability: MentalDisability,
  number_1: Number1,
  number_2: Number2,
  number_3: Number3,
  number_4: Number4,
  onsite_activities: OnsiteActivities,
  padlock: Padlock,
  recent_search_trip: RecentSearchTrip,
  show: Show,
  snowflake: Snowflake,
  stadium: Stadium,
  star_empty: StarEmpty,
  star_outline: StarOutline,
  sun: Sun,
  sun_glasses: SunGlasses,
  terms: Terms,
  the_mask: TheMask,
  thumb_up: ThumbUp,
  tip: Tip,
  tip_full: TipFull,
  viewpoint: Viewpoint,
}

export const getDiversIcon = (diversKey: string | undefined): LazyIcon | undefined =>
  diversKey && isDiversIcon(diversKey) ? ICONS[diversKey] : undefined

export const getDiversIcons = (): LazyIconsGroup<DiversIcon> => ICONS
