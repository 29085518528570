// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Bubble = svgLazyLoader('communication/bubble')
const BubbleDot = svgLazyLoader('communication/bubble_dot')
const BubbleDotFull = svgLazyLoader('communication/bubble_dot_full')
const BubbleInfo = svgLazyLoader('communication/bubble_info')
const BubbleReport = svgLazyLoader('communication/bubble_report')
const CallCenter = svgLazyLoader('communication/call_center')
const ConnectedWatch = svgLazyLoader('communication/connected_watch')
const EmergencyCall = svgLazyLoader('communication/emergency_call')
const HelpBubble = svgLazyLoader('communication/help_bubble')
const Laptop = svgLazyLoader('communication/laptop')
const Micro = svgLazyLoader('communication/micro')
const MobilePhone = svgLazyLoader('communication/mobile_phone')
const MultiDevices = svgLazyLoader('communication/multi_devices')
const Report = svgLazyLoader('communication/report')
const ReportFull = svgLazyLoader('communication/report_full')
const SendMessage = svgLazyLoader('communication/send_message')
const SoundReport = svgLazyLoader('communication/sound_report')
const Tablet = svgLazyLoader('communication/tablet')

const ALL_COMMUNICATION_ICONS = [
  'bubble',
  'bubble_dot',
  'bubble_dot_full',
  'bubble_info',
  'bubble_report',
  'call_center',
  'connected_watch',
  'emergency_call',
  'help_bubble',
  'laptop',
  'micro',
  'mobile_phone',
  'multi_devices',
  'report',
  'report_full',
  'send-message',
  'sound_report',
  'tablet',
] as const
export type CommunicationIcon = (typeof ALL_COMMUNICATION_ICONS)[number]

const isCommunicationIcon = (communicationKey: string): communicationKey is CommunicationIcon =>
  ALL_COMMUNICATION_ICONS.includes(communicationKey as CommunicationIcon)

const ICONS: LazyIconsGroup<CommunicationIcon> = {
  bubble: Bubble,
  bubble_dot: BubbleDot,
  bubble_dot_full: BubbleDotFull,
  bubble_info: BubbleInfo,
  bubble_report: BubbleReport,
  call_center: CallCenter,
  connected_watch: ConnectedWatch,
  emergency_call: EmergencyCall,
  help_bubble: HelpBubble,
  laptop: Laptop,
  micro: Micro,
  mobile_phone: MobilePhone,
  multi_devices: MultiDevices,
  report: Report,
  report_full: ReportFull,
  'send-message': SendMessage,
  sound_report: SoundReport,
  tablet: Tablet,
}

export const getCommunicationIcon = (communicationKey: string | undefined): LazyIcon | undefined =>
  communicationKey && isCommunicationIcon(communicationKey) ? ICONS[communicationKey] : undefined

export const getCommunicationIcons = (): LazyIconsGroup<CommunicationIcon> => ICONS
