import { css, SerializedStyles } from '@emotion/react'
import { cardActionAreaClasses } from '@mui/material/CardActionArea'
import { linkClasses } from '@mui/material/Link'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { QUARTER_RADIUS, STANDARD_RADIUS } from '@Styles/constants/radius'
import { SPACING_0, SPACING_2 } from '@Styles/constants/spacing'
import { getFocusVisibleStyle } from '@Styles/focus'

const ICON_SIZE = rem(16)
const ICON_SPACING = rem(6)

export const link = (
  theme: Theme,
  hasNoSpacing = false,
  isInline = false,
  hasDefaultFocusVisible = false,
  isFullWidth = false
): SerializedStyles =>
  css(
    {
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:hover, &:focus, &:active': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      cursor: 'pointer',
      position: 'relative',
    },
    isInline
      ? { display: 'inline' }
      : {
          alignItems: 'center',
          display: 'inline-flex',
        },
    !hasNoSpacing && { padding: `calc(${SPACING_2} - ${rem(1)})` }, // Zone de tap de 48px
    hasDefaultFocusVisible
      ? {
          '&:focus-visible': {
            borderRadius: rem(2),
            outline: `2px solid ${theme.palette.primary.main}`, // Same as focusVisible outline's style
          },
        }
      : {
          [`&.${linkClasses.focusVisible} .${cardActionAreaClasses.focusHighlight}`]: {
            opacity: 0,
          },
          '&:focus-visible': {
            outline: 'none',
          },
          '&:focus-visible::before': getFocusVisibleStyle(
            theme.palette.primary.main,
            hasNoSpacing ? QUARTER_RADIUS : STANDARD_RADIUS,
            hasNoSpacing ? `-${SPACING_0}` : '0'
          ),
        },
    isFullWidth && {
      justifyContent: 'center',
      width: '100%',
    }
  )

export const icon = (isInline = false): SerializedStyles =>
  css(
    {
      ...size(ICON_SIZE),
      alignSelf: 'normal',
      fill: 'currentcolor',
      flexShrink: 0,
      marginLeft: ICON_SPACING,
    },
    isInline && { position: 'relative', top: '3px' }
  )
