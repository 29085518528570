import {
  Cart,
  CartBuyer,
  CartInsurance,
  CartItem,
  CartItemDeliveryMode,
  CartItemType,
  CartSubscriptionItemDeliveryMode,
  CartSummaryItem,
  CartTraveler,
  CartTravelerDiscountCard,
  DeliveryModeDescriptor,
  DeliveryModeDetails,
  DeliveryModeForGroupId,
  PersonalData,
  SubscriptionCartItem,
  TripCartItem,
} from 'invictus-sdk-typescript'

import { SelectablePerson } from '@Components/container/contactInformation/types'
import { IdentityName } from '@DS/components/components/avatar/avatarSummary/AvatarSummary.types'
import { getDeliveryModeIcon } from '@DS/components/icons/deliveryModeIcons'
import { LazyIcon } from '@DS/components/icons/icons.types'
import { getPaymentIcon } from '@DS/components/icons/paymentIcons'
import { CartTravelerByItem, ExpiredItem } from '@Types/cart'
import { getCivility } from '@Utils/civility'
import { humanDateToIsoDate } from '@Utils/date'

import { haveSameIdentity } from './buyer'

const getTravelersFromTrip = (id: string, trip: TripCartItem): CartTravelerByItem[] =>
  trip.travelers.map((traveler) => ({
    startingDate: trip.departureDateReference,
    traveler,
    cartItemId: id,
    cartItemType: 'trip',
  }))

const getTravelersFromSubscription = (id: string, subscription: SubscriptionCartItem): CartTravelerByItem[] =>
  subscription.travelers.map((traveler) => ({
    startingDate: subscription.validityStartDate,
    traveler,
    cartItemId: id,
    cartItemType: 'subscription',
  }))

export const getCartTravelers = (cart: Cart | undefined): CartTravelerByItem[] =>
  cart
    ? cart.items.flatMap((cartItem) => {
        if (cartItem.trip) {
          return getTravelersFromTrip(cartItem.id, cartItem.trip)
        }

        if (cartItem.subscription) {
          return getTravelersFromSubscription(cartItem.id, cartItem.subscription)
        }

        // TODO: Comment gérer le cas Ticket ?

        return []
      })
    : []

export const getTravelersForCartItemId = (travelers: CartTravelerByItem[], cartItemId: string): CartTravelerByItem[] =>
  travelers.filter((traveler) => traveler.cartItemId === cartItemId)

export const getHasAnInsuranceSelected = (cart?: Cart): boolean =>
  !!cart?.items.some((item) => item.trip?.cartInsurance?.selected)

export const selectedInsurances = (cart?: Cart): CartInsurance[] | undefined =>
  cart?.items.reduce<CartInsurance[]>((accSelectedInsurance, item) => {
    if (item.trip?.cartInsurance?.selected) {
      accSelectedInsurance.push(item.trip.cartInsurance)
    }

    return accSelectedInsurance
  }, [])

const getHasASelectedDonation = (cart?: Cart): boolean => !!cart?.selectedDonations.length

export const getSummaryLabels = (cart?: Cart): CartSummaryItem[] => {
  const labels = cart?.summary.itemsWithPrices ?? []

  if (getHasASelectedDonation(cart) && cart?.summary.donationWithPrice?.label) {
    return [...labels, { label: cart.summary.donationWithPrice.label, price: cart.summary.donationWithPrice.price }]
  }

  return labels
}

export const mapCartBuyerFromAccount = (
  { civility, email, firstname, lastname, phone, address, birthdate }: PersonalData,
  isAddressRequired = false
): CartBuyer => ({
  civility: getCivility(civility),
  firstName: firstname,
  lastName: lastname,
  unsubscribeToNewsletter: undefined,
  isPhoneNumberMandatory: false,
  email,
  dateOfBirth: birthdate,
  phoneNumber: phone,
  ...(isAddressRequired && { address }),
})

export const toExpiredItemTrip = ({ id, trip }: CartItem): ExpiredItem => ({
  id: id as string,
  type: CartItemType.TRIP,
  dateAndTime: trip?.outwardJourney?.departureDateTimeLabel ?? '',
  destination: trip?.outwardJourney?.destinationStationLabel ?? '',
})

export const toExpiredItemSubscription = ({ id, subscription }: CartItem): ExpiredItem => ({
  id: id as string,
  type: CartItemType.SUBSCRIPTION,
  title: subscription?.title as string,
})

export const toExpiredItem = (item: CartItem | undefined): ExpiredItem | null => {
  switch (item?.type) {
    case CartItemType.TRIP:
      return toExpiredItemTrip(item)
    case CartItemType.SUBSCRIPTION:
      return toExpiredItemSubscription(item)
    default:
      // Unknown item type
      return null
  }
}

export const getExpiredItems = ({
  cartItems,
  previousCartItems,
  deletedItemId,
}: {
  cartItems: CartItem[]
  previousCartItems: CartItem[]
  deletedItemId: string | undefined
}): ExpiredItem[] | undefined =>
  previousCartItems.reduce<ExpiredItem[]>((acc, currentValue) => {
    // Whether local item is no more in fetched cart items
    const hasItemBeenRemoved = !cartItems.some((item) => item.id === currentValue.id)
    const isIntentionalRemoval = deletedItemId === currentValue.id

    if (!hasItemBeenRemoved || isIntentionalRemoval) {
      return acc
    }
    const expiredItem = toExpiredItem(currentValue)

    if (!expiredItem) {
      return acc
    }
    acc.push(expiredItem)

    return acc
  }, [])

export const getTotalPriceWithInsuranceAndDonations = (cart?: Cart): number | undefined => {
  const sumTotal = cart?.items.reduce(
    (total, { trip: tripItem }) => total + (tripItem?.cartInsurance?.selected ? tripItem.cartInsurance.price.value : 0),
    cart.totalWithoutInsuranceAndDonationPrice.value
  )

  return cart?.selectedDonations.reduce((total, { amount: { value } }) => total + value, sumTotal || 0)
}

export const travelerDisplayName = (placeholder: string, firstName?: string, lastname?: string): IdentityName =>
  !firstName ? { firstName: placeholder, lastName: '' } : { firstName, lastName: lastname ?? '' }

export const digipassCardName = ({ holder, title }: SubscriptionCartItem): string =>
  `${holder?.firstName ?? ''} ${holder?.lastName ?? ``} ${title}`

const getCartTravelerDiscountCardWithNumber = (
  cartTravelerDiscountCard: CartTravelerDiscountCard | undefined,
  number = ''
): CartTravelerDiscountCard | undefined =>
  cartTravelerDiscountCard ? { ...cartTravelerDiscountCard, number, isNumberError: !number } : undefined

export const updateTravelerWithSubscription = (
  traveler: CartTraveler,
  { cardNumber, holder }: SubscriptionCartItem
): CartTraveler => ({
  ...traveler,
  firstName: holder?.firstName,
  lastName: holder?.lastName,
  email: holder?.email,
  phoneNumber: holder?.phoneNumber,
  dateOfBirthLabel: holder?.birthDate,
  dateOfBirth: holder?.birthDate ? humanDateToIsoDate(holder.birthDate) : undefined,
  civility: getCivility(holder?.civility),
  discountCard: getCartTravelerDiscountCardWithNumber(traveler.discountCard, cardNumber),
  editable: false,
})

export const resetCartTraveler = (traveler: CartTraveler): CartTraveler => ({
  ...traveler,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phoneNumber: undefined,
  civility: undefined,
  dateOfBirthLabel: undefined,
  dateOfBirth: undefined,
  discountCard: getCartTravelerDiscountCardWithNumber(traveler.discountCard),
  editable: true,
})

export const isAnonymous = (traveler: SelectablePerson): boolean =>
  !traveler.firstName && !traveler.lastName && !traveler.email && !traveler.phoneNumber && !traveler.dateOfBirth

export const isInTravelers = (person: CartBuyer, cartTravelers: CartTravelerByItem[]): boolean =>
  cartTravelers.some(({ traveler }) => haveSameIdentity(traveler, person))

export const isTheBuyer = (person: CartBuyer, buyer: CartBuyer | undefined): boolean => haveSameIdentity(person, buyer)

export const getTravelerContextualId = (cartItemId: string, travelerId: string): string => `${cartItemId}/${travelerId}`

export const getDeliveryModesByGroupId = (selectedDeliveryModes: DeliveryModeForGroupId[]): Record<string, string> =>
  selectedDeliveryModes.reduce<Record<string, string>>((acc, { deliveryMode, groupId }) => {
    acc[groupId] = deliveryMode

    return acc
  }, {})

const getCartItemDeliveryModeGroups = (deliveryMode?: CartItemDeliveryMode): DeliveryModeForGroupId[] => {
  const deliveryModeItems =
    (deliveryMode?.trip?.items ||
      (deliveryMode?.subscription && [{ ...deliveryMode.subscription, selectedDeliveryMode: undefined }])) ??
    []

  return deliveryModeItems.map(
    ({ availableDeliveryModes, groupId, selectedDeliveryMode = availableDeliveryModes[0] }) => ({
      groupId,
      deliveryMode: selectedDeliveryMode.value,
      addressRequired: selectedDeliveryMode.addressRequired,
    })
  )
}

export const getCartDeliveryModeGroups = (cartItems: CartItem[]): DeliveryModeForGroupId[] =>
  cartItems.flatMap(({ deliveryMode }) => getCartItemDeliveryModeGroups(deliveryMode))

/* Fix Talkback interpretations problems : https://jira.vsct.fr/browse/IVTS-6581 */
export const getAnonymousDisplayName = (label?: string): string | undefined => label?.replace(':', ': \ufeff')

export const getDeliveryModeDetails = (
  deliveryModes: DeliveryModeForGroupId[],
  itemDeliveryModeSubscription: CartSubscriptionItemDeliveryMode | undefined,
  itemDeliveryMode: DeliveryModeDescriptor | undefined
): DeliveryModeDetails | undefined => {
  const selectedDeliveryModeByGroupId = deliveryModes.find(
    (deliveryModeSubscription) => deliveryModeSubscription.groupId === itemDeliveryModeSubscription?.groupId
  )?.deliveryMode

  return itemDeliveryModeSubscription
    ? itemDeliveryModeSubscription.availableDeliveryModes.find(({ value }) => value === selectedDeliveryModeByGroupId)
        ?.details
    : itemDeliveryMode?.details
}

export const getDeliveryModeIcons = (deliveryMode: string): LazyIcon | undefined =>
  deliveryMode === 'ebillet' ? getPaymentIcon(deliveryMode) : getDeliveryModeIcon(deliveryMode || 'EAD')

export const getInsuranceSubtitle = (
  { insuranceId, insuranceLabel, insurancePrice }: CartSummaryItem,
  insurances?: CartInsurance[]
): [{ subtitle: string; subtitlePrice?: string }] | undefined =>
  insurances?.find(({ id }) => id === insuranceId) && insuranceLabel
    ? [{ subtitle: insuranceLabel, subtitlePrice: insurancePrice }]
    : undefined
