// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@Components/designSystem/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const PlainCircleAccessibility = svgLazyLoader('plainCircle/plain_circle_accessibility')
const PlainCircleAgent = svgLazyLoader('plainCircle/plain_circle_agent')
const PlainCircleAvatar = svgLazyLoader('plainCircle/plain_circle_avatar')
const PlainCircleBike = svgLazyLoader('plainCircle/plain_circle_bike')
const PlainCircleBikeCover = svgLazyLoader('plainCircle/plain_circle_bike_cover')
const PlainCircleCalendar = svgLazyLoader('plainCircle/plain_circle_calendar')
const PlainCircleCompagnonAvatar = svgLazyLoader('plainCircle/plain_circle_compagnon_avatar')
const PlainCircleFoldedBike = svgLazyLoader('plainCircle/plain_circle_folded_bike')
const PlainCircleGeolocation = svgLazyLoader('plainCircle/plain_circle_geolocation')
const PlainCircleHome = svgLazyLoader('plainCircle/plain_circle_home')
const PlainCircleNursery = svgLazyLoader('plainCircle/plain_circle_nursery')
const PlainCirclePet = svgLazyLoader('plainCircle/plain_circle_pet')
const PlainCircleProArea = svgLazyLoader('plainCircle/plain_circle_pro_area')
const PlainCircleSuitcase = svgLazyLoader('plainCircle/plain_circle_suitcase')
const PlainCircleTripsPlanned = svgLazyLoader('plainCircle/plain_circle_trips_planned')
const PlainCircleVtc = svgLazyLoader('plainCircle/plain_circle_vtc')

const ALL_PLAIN_CIRCLE_ICONS = [
  'plain_circle_accessibility',
  'plain_circle_agent',
  'plain_circle_avatar',
  'plain_circle_bike',
  'plain_circle_bike_cover',
  'plain_circle_calendar',
  'plain_circle_compagnon_avatar',
  'plain_circle_folded_bike',
  'plain_circle_geolocation',
  'plain_circle_home',
  'plain_circle_nursery',
  'plain_circle_pet',
  'plain_circle_pro_area',
  'plain_circle_suitcase',
  'plain_circle_trips_planned',
  'plain_circle_vtc',
] as const
export type PlainCircleIcon = (typeof ALL_PLAIN_CIRCLE_ICONS)[number]

const isPlainCircleIcon = (plainCircleKey: string): plainCircleKey is PlainCircleIcon =>
  ALL_PLAIN_CIRCLE_ICONS.includes(plainCircleKey as PlainCircleIcon)

const ICONS: LazyIconsGroup<PlainCircleIcon> = {
  plain_circle_accessibility: PlainCircleAccessibility,
  plain_circle_agent: PlainCircleAgent,
  plain_circle_avatar: PlainCircleAvatar,
  plain_circle_bike: PlainCircleBike,
  plain_circle_bike_cover: PlainCircleBikeCover,
  plain_circle_calendar: PlainCircleCalendar,
  plain_circle_compagnon_avatar: PlainCircleCompagnonAvatar,
  plain_circle_folded_bike: PlainCircleFoldedBike,
  plain_circle_geolocation: PlainCircleGeolocation,
  plain_circle_home: PlainCircleHome,
  plain_circle_nursery: PlainCircleNursery,
  plain_circle_pet: PlainCirclePet,
  plain_circle_pro_area: PlainCircleProArea,
  plain_circle_suitcase: PlainCircleSuitcase,
  plain_circle_trips_planned: PlainCircleTripsPlanned,
  plain_circle_vtc: PlainCircleVtc,
}

export const getPlainCircleIcon = (plainCircleKey: string | undefined): LazyIcon | undefined =>
  plainCircleKey && isPlainCircleIcon(plainCircleKey) ? ICONS[plainCircleKey] : undefined

export const getPlainCircleIcons = (): LazyIconsGroup<PlainCircleIcon> => ICONS
