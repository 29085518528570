import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SeverityType } from '@DS/types/severity'
import { getColorBySeverity } from '@DS/utils/severity'
import { HALF_RADIUS } from '@Styles/constants/radius'
import { SPACING_2, SPACING_3, SPACING_4 } from '@Styles/constants/spacing'

/**
 * @deprecated to delete when IVTS-49140 is enabled in production
 */
export const snackbarContainer = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.primary,
    justifyContent: 'center',
    marginBottom: SPACING_4,
  })

/**
 * @deprecated to delete when IVTS-49140 is enabled in production
 */
export const actionLabel = (theme: Theme): SerializedStyles =>
  css({
    backgroundColor: 'transparent',
    border: 0,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    flexShrink: 0,
    marginLeft: 'auto',
    padding: `0 0 0 ${SPACING_2}`,
  })

/**
 * @deprecated to delete when IVTS-49140 is enabled in production
 */
export const snackbarContent = (theme: Theme): SerializedStyles =>
  css({
    alignItems: 'center',
    backgroundColor: theme.customPalette.onElements.elevated,
    borderRadius: HALF_RADIUS,
    display: 'flex',
    padding: SPACING_2,
    [theme.breakpoints.up('md')]: {
      minWidth: rem(672),
    },
  })

/**
 * @deprecated to delete when IVTS-49140 is enabled in production
 */
export const snackbarIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.text.primary,
    flexShrink: 0,
    marginRight: SPACING_2,
    ...size(SPACING_3),
  })

export const action = (theme: Theme): SerializedStyles =>
  css({
    backgroundColor: 'transparent',
    border: 0,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    flexShrink: 0,
    marginLeft: 'auto',
    padding: `0 0 0 ${SPACING_2}`,
  })

export const content = (theme: Theme): SerializedStyles =>
  css({
    alignItems: 'center',
    backgroundColor: theme.customPalette.onElements.elevated,
    borderRadius: HALF_RADIUS,
    display: 'flex',
    padding: SPACING_2,
    [theme.breakpoints.up('md')]: {
      minWidth: rem(672),
    },
  })

export const icon = (theme: Theme, severity?: SeverityType): SerializedStyles =>
  css({
    fill: severity ? getColorBySeverity(theme, severity) : theme.palette.text.primary,
    flexShrink: 0,
    marginRight: SPACING_2,
    ...size(SPACING_3),
  })
